<template>
  <v-dialog v-model="isOpen" max-width="700">
    <v-card v-if="selected" rounded="lg" elevation="0" class="card-modal">
      <div>
        <v-card-title>
          <v-spacer />
          <div>{{ selected.name }}</div>
          <v-spacer />
        </v-card-title>
        <v-card-subtitle>
          {{ $i18n.t("rankings.dialog.id", { fundId: selected.fundId }) }}
        </v-card-subtitle>
        <v-row no-gutters justify="center" class="my-1">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-chip :color="colors[0]" class="class-chip" v-bind="attrs" v-on="on">
                {{ $i18n.t(`selectors.rankings.fund.fund.${selected.category}`) }}
              </v-chip>
            </template>
            <span>{{ $i18n.t("selectors.rankings.fund.hover") }}</span>
          </v-tooltip>
        </v-row>
        <v-row no-gutters justify="center" class="my-1">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-chip :color="colors[1]" class="class-chip" v-bind="attrs" v-on="on">
                {{ $i18n.t(`selectors.rankings.asset.asset1.${selected.asset1}`) }}
              </v-chip>
            </template>
            <span>{{ $i18n.t("selectors.rankings.asset.hover") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="selected.asset2 >= 1">
            <template #activator="{ on, attrs }">
              <v-chip :color="colors[1]" class="class-chip" v-bind="attrs" v-on="on">
                {{ $i18n.t(`selectors.rankings.asset.asset2.${selected.asset2}`) }}
              </v-chip>
            </template>
            <span>{{ $i18n.t("selectors.rankings.asset.hover") }}</span>
          </v-tooltip>
        </v-row>
        <v-row no-gutters justify="center" class="my-1">
          <v-tooltip bottom v-if="selected.theme === 1">
            <template #activator="{ on, attrs }">
              <v-chip :color="colors[2]" class="class-chip" v-bind="attrs" v-on="on">
                {{ $i18n.t("selectors.rankings.theme.title") }}
              </v-chip>
            </template>
            <span>{{ $i18n.t("selectors.rankings.theme.hover") }}</span>
          </v-tooltip>
        </v-row>
        <v-row no-gutters justify="center" class="my-1">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-chip :color="colors[3]" class="class-chip" v-bind="attrs" v-on="on">
                {{ $i18n.t(`selectors.rankings.geography.geography1.${selected.geography1}`) }}
              </v-chip>
            </template>
            <span>{{ $i18n.t("selectors.rankings.geography.hover") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="selected.geography2 >= 1">
            <template #activator="{ on, attrs }">
              <v-chip :color="colors[3]" class="class-chip" v-bind="attrs" v-on="on">
                {{ $i18n.t(`selectors.rankings.geography.geography2.${selected.geography2}`) }}
              </v-chip>
            </template>
            <span>{{ $i18n.t("selectors.rankings.geography.hover") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="selected.geography3 >= 1">
            <template #activator="{ on, attrs }">
              <v-chip :color="colors[3]" class="class-chip" v-bind="attrs" v-on="on">
                {{ $i18n.t(`selectors.rankings.geography.geography3.${selected.geography3}`) }}
              </v-chip>
            </template>
            <span>{{ $i18n.t("selectors.rankings.geography.hover") }}</span>
          </v-tooltip>
        </v-row>
        <v-card-subtitle>{{ $i18n.t("rankings.dialog.series") }}</v-card-subtitle>
        <v-list
          :class="{
            'horizontal-spacing': !isMobile,
            'horizontal-spacing-mobile': isMobile,
          }"
        >
          <v-list-item
            v-for="(series, index) in selected.series"
            :key="index"
            :style="{
              'background-color': index % 2 === 0 ? '#F2F2F2' : '#FFFFFF',
            }"
          >
            <div>{{ series.series }}</div>
            <!-- <v-spacer />
            <v-btn
              rounded
              x-large
              :icon="isMobile"
              :class="{
                'button-gradient': true,
                'rankings-add': !isMobile,
                'full-button': isMobile,
              }"
              elevation="0"
              height="40px"
              @click="compareFund(series.series, selected)"
              id="rankings-add-to-compare"
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
              <div v-if="!isMobile">
                <v-spacer />
                <div>{{ $i18n.t("rankings.dialog.add") }}</div>
              </div>
            </v-btn> -->
          </v-list-item>
        </v-list>
        <!-- <v-btn text small color="#828282" @click="openIntercom">
          <v-icon>mdi-help-circle-outline</v-icon>
          <div>{{ $i18n.t("rankings.dialog.wrong.button") }}</div>
        </v-btn> -->
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import colors from "@/helper/colors";

const moment = require("moment-timezone");

export default {
  name: "RankingDialog",
  data() {
    return {
      colors,
    };
  },
  props: {
    dialog: Boolean,
    selected: Object,
  },
  computed: {
    isOpen: {
      get() {
        return this.dialog;
      },
      set(newValue) {
        this.$emit("updateDialog", newValue);
      },
    },
    isMobile() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return true;
      }
      return false;
    },
  },
  methods: {
    compareFund(series, selected) {
      const startDate = moment.tz(`${this.$store.state.selectedRankingDate} 12:00`, "America/Santiago");
      const endDate = moment.tz(`${this.$store.state.selectedRankingDate} 12:00`, "America/Santiago");
      startDate.subtract(1, "years").endOf("year");
      this.$router.push({
        name: "home",
        query: {
          funds: `${selected.category}_${selected.fundId}_${series}`,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
      });
    },
    openIntercom() {
      this.$intercom.showNewMessage(this.$i18n.t("rankings.dialog.wrong.intercom", { fund: this.selected.name }));
    },
  },
};
</script>

<style scoped>
.class-chip {
  color: white;
  font-weight: bold;
  margin-left: 8px;
  margin-right: 8px;
}
.rankings-add {
  color: linear-gradient(222.87deg, #832fed 7.03%, #4e5ff1 74.2%) !important;
  font-weight: bold;
  font-size: 12px;
}
</style>
