<template>
  <div
    :class="{
      'vertical-spacing-mobile': true,
    }"
  >
    <v-row no-gutters>
      <fund-search v-if="!isMobile" :loading="loading" />
      <v-spacer />
      <currency-selector :loading="loading" :buttonHeight="buttonHeight" />
      <div id="date-selectors">
        <v-menu offset-y rounded="lg" nudge-bottom="5">
          <template #activator="{ on, attrs }">
            <v-btn
              large
              elevation="0"
              :class="{
                'button-font-size': true,
                'button-gradient': !loading && $store.state.selectedDate.code !== 'custom',
                'button-inactive': !loading && $store.state.selectedDate.code === 'custom',
                'left-currency': !isMobile,
                'full-button': isMobile,
              }"
              :min-width="isMobile ? 0 : 180"
              :disabled="loading"
              :min-height="buttonHeight"
              :max-height="buttonHeight"
              v-on="on"
              v-bind="attrs"
              id="date-selector"
            >
              <v-row no-gutters>
                <div class="date-selector-text">
                  {{ $i18n.t(`selectors.dates.buttons.${isMobile ? "name" : "longName"}.${$store.state.selectedDate.code}`) }}
                </div>
                <v-spacer />
                <v-icon>mdi-chevron-down</v-icon>
              </v-row>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(item, index) in possibleDates">
              <v-list-item :key="index * 2" @click="setDates(item)">
                <v-list-item-title>{{ item.longName }}</v-list-item-title>
              </v-list-item>
              <v-divider :key="index * 2 + 1" />
            </template>
          </v-list>
        </v-menu>
        <custom-date-selector v-if="!isMobile" :loading="loading" :buttonHeight="buttonHeight" />
      </div>
      <mobile-fund-selector :loading="loading" :height="buttonHeight" v-if="isMobile" />
      <v-spacer v-if="isMobile" />
    </v-row>
  </div>
</template>

<script>
/* eslint-disable arrow-parens */
import CustomDateSelector from "@/components/CustomDateSelector.vue";
import FundSearch from "@/components/FundSearch.vue";
import CurrencySelector from "@/components/inner/CurrencySelector.vue";
import MobileFundSelector from "@/components/MobileFundSelector.vue";

const moment = require("moment-timezone");

export default {
  name: "Selectors",
  components: {
    CustomDateSelector,
    FundSearch,
    CurrencySelector,
    MobileFundSelector,
  },
  data() {
    return {
      buttonHeight: "50px",
      allDates: [
        {
          name: this.$i18n.t("selectors.dates.buttons.name.MTD"),
          longName: this.$i18n.t("selectors.dates.buttons.longName.MTD"),
          code: "MTD",
        },
        {
          name: this.$i18n.t("selectors.dates.buttons.name.LM"),
          longName: this.$i18n.t("selectors.dates.buttons.longName.LM"),
          code: "LM",
        },
        {
          name: this.$i18n.t("selectors.dates.buttons.name.YTD"),
          longName: this.$i18n.t("selectors.dates.buttons.longName.YTD"),
          code: "YTD",
        },
        {
          name: this.$i18n.t("selectors.dates.buttons.name.LY"),
          longName: this.$i18n.t("selectors.dates.buttons.longName.LY"),
          code: "LY",
        },
        {
          name: this.$i18n.t("selectors.dates.buttons.name.Y1"),
          longName: this.$i18n.t("selectors.dates.buttons.longName.Y1"),
          code: "Y1",
        },
        {
          name: this.$i18n.t("selectors.dates.buttons.name.Y3"),
          longName: this.$i18n.t("selectors.dates.buttons.longName.Y3"),
          code: "Y3",
        },
        {
          name: this.$i18n.t("selectors.dates.buttons.name.Y5"),
          longName: this.$i18n.t("selectors.dates.buttons.longName.Y5"),
          code: "Y5",
        },
      ],
    };
  },
  props: {
    loading: Boolean,
  },
  computed: {
    possibleDates() {
      return this.allDates.filter(dt => dt.code !== this.$store.state.selectedDate.code);
    },
    isMobile() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return true;
      }
      return false;
    },
    isMedium() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        return true;
      }
      return false;
    },
  },
  methods: {
    setDates(item) {
      const startDate = moment.tz(moment(), "America/Santiago");
      const endDate = moment.tz(moment(), "America/Santiago");
      if (item.code === "MTD") {
        startDate.subtract(1, "months").endOf("month");
        endDate.subtract(1, "days");
      }
      if (item.code === "LM") {
        startDate.subtract(2, "months").endOf("month");
        endDate.subtract(1, "months").endOf("month");
      }
      if (item.code === "YTD") {
        startDate.subtract(1, "years").endOf("year");
        endDate.subtract(1, "days");
      }
      if (item.code === "Y1") {
        startDate.subtract(1, "days").subtract(1, "years");
        endDate.subtract(1, "days");
      }
      if (item.code === "LY") {
        startDate.subtract(2, "years").endOf("year");
        endDate.subtract(1, "years").endOf("year");
      }
      if (item.code === "Y3") {
        startDate.subtract(1, "days").subtract(3, "years");
        endDate.subtract(1, "days");
      }
      if (item.code === "Y5") {
        startDate.subtract(1, "days").subtract(5, "years");
        endDate.subtract(1, "days");
      }
      this.$store.commit("setDates", {
        ...item,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      });
    },
  },
};
</script>

<style scoped>
.button-font-size {
  font-size: 12px;
}
.date-selector-text {
  margin-top: 5px;
}
</style>
