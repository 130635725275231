<template>
  <div v-if="standalone">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn icon class="ma-2" v-bind="attrs" v-on="on" id="download-funds">
          <vue-excel-xlsx :data="rows" :columns="columns" :filename="filename" :sheetname="'workbook'">
            <v-icon>mdi-download</v-icon>
          </vue-excel-xlsx>
        </v-btn>
      </template>
      <span>{{ $i18n.t("table.buttons.tooltips.download") }}</span>
    </v-tooltip>
  </div>
  <vue-excel-xlsx :data="rows" :columns="columns" :filename="filename" :sheetname="'workbook'" v-else>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-download</v-icon>
      </v-list-item-icon>
      <v-list-item-title>{{ $i18n.t("table.buttons.tooltips.download") }}</v-list-item-title>
    </v-list-item>
  </vue-excel-xlsx>
</template>

<script>
/* eslint-disable arrow-parens, implicit-arrow-linebreak, operator-linebreak */
export default {
  name: "RankingDownloadData",
  data() {
    return {
      asset1Dependencies: {
        0: null,
        1: null,
        2: [1, 2],
        3: [3, 4],
        4: null,
        5: [5, 6, 7],
      },
      geography1Dependencies: {
        1: null,
        2: [1, 2, 3, 4, 5, 6],
        3: [7, 8, 9],
      },
      geography2Dependencies: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: [1, 2, 3, 4],
        8: [5, 6, 7],
        9: null,
      },
    };
  },
  props: {
    standalone: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rows() {
      return this.$store.state.selectedRanking
        .filter(item => this.$store.state.selectedRankingFilters.fund[item.category])
        .filter(item => this.$store.state.selectedRankingFilters.asset1[item.asset1])
        .filter(
          item =>
            !this.asset1Dependencies[item.asset1] ||
            (this.$store.state.selectedRankingFilters.asset1[item.asset1] &&
              this.asset1Dependencies[item.asset1] &&
              this.$store.state.selectedRankingFilters.asset2[item.asset2]),
        )
        .filter(item => this.$store.state.selectedRankingFilters.theme[item.theme])
        .filter(item => this.$store.state.selectedRankingFilters.geography1[item.geography1])
        .filter(
          item =>
            !this.geography1Dependencies[item.geography1] ||
            (this.$store.state.selectedRankingFilters.geography1[item.geography1] &&
              this.geography1Dependencies[item.geography1] &&
              this.$store.state.selectedRankingFilters.geography2[item.geography2]),
        )
        .filter(
          item =>
            !this.geography2Dependencies[item.geography2] ||
            (this.$store.state.selectedRankingFilters.geography2[item.geography2] &&
              this.geography2Dependencies[item.geography2] &&
              this.$store.state.selectedRankingFilters.geography3[item.geography3]),
        )
        .filter(
          item =>
            !this.$store.state.selectedRankingFilters.equityLesserGreater[0] ||
            (this.$store.state.selectedRankingFilters.equityLesserGreater[0] &&
              item.equity >= this.$store.state.selectedRankingFilters.equityValues[0]),
        )
        .filter(
          item =>
            !this.$store.state.selectedRankingFilters.equityLesserGreater[1] ||
            (this.$store.state.selectedRankingFilters.equityLesserGreater[1] &&
              item.equity <= this.$store.state.selectedRankingFilters.equityValues[1]),
        );
    },
    columns() {
      return [
        {
          label: this.$i18n.t("rankings.download.id"),
          field: "fundId",
          visible: true,
        },
        {
          label: this.$i18n.t("rankings.download.name"),
          field: "name",
          visible: true,
        },
        {
          label: this.$i18n.t("rankings.download.category"),
          field: "category",
          dataFormat: this.nameFund,
          visible: true,
        },
        {
          label: this.$i18n.t("rankings.download.asset"),
          field: "asset1",
          dataFormat: this.nameAsset1,
          visible: true,
        },
        {
          label: this.$i18n.t("rankings.download.asset"),
          field: "asset2",
          dataFormat: this.nameAsset2,
          visible: true,
        },
        {
          label: this.$i18n.t("rankings.download.theme"),
          field: "theme",
          dataFormat: this.nameTheme,
          visible: true,
        },
        {
          label: this.$i18n.t("rankings.download.geography"),
          field: "geography1",
          dataFormat: this.nameGeography1,
          visible: true,
        },
        {
          label: this.$i18n.t("rankings.download.geography"),
          field: "geography2",
          dataFormat: this.nameGeography2,
          visible: true,
        },
        {
          label: this.$i18n.t("rankings.download.geography"),
          field: "geography3",
          dataFormat: this.nameGeography3,
          visible: true,
        },
        {
          label: this.$i18n.t("rankings.download.YTD"),
          field: "ytd",
          visible: this.$store.state.selectedRankingFilters.headers.ytd,
        },
        {
          label: this.$i18n.t("rankings.download.M1"),
          field: "M1",
          visible: this.$store.state.selectedRankingFilters.headers.M1,
        },
        {
          label: this.$i18n.t("rankings.download.M3"),
          field: "M3",
          visible: this.$store.state.selectedRankingFilters.headers.M3,
        },
        {
          label: this.$i18n.t("rankings.download.M6"),
          field: "M6",
          visible: this.$store.state.selectedRankingFilters.headers.M6,
        },
        {
          label: this.$i18n.t("rankings.download.Y1"),
          field: "Y1",
          visible: this.$store.state.selectedRankingFilters.headers.Y1,
        },
        {
          label: this.$i18n.t("rankings.download.Y2"),
          field: "Y2",
          visible: this.$store.state.selectedRankingFilters.headers.Y2,
        },
        {
          label: this.$i18n.t("rankings.download.Y3"),
          field: "Y3",
          visible: this.$store.state.selectedRankingFilters.headers.Y3,
        },
        {
          label: this.$i18n.t("rankings.download.Y5"),
          field: "Y5",
          visible: this.$store.state.selectedRankingFilters.headers.Y5,
        },
        {
          label: this.$i18n.t("rankings.download.Y10"),
          field: "Y10",
          visible: this.$store.state.selectedRankingFilters.headers.Y10,
        },
        {
          label: this.$i18n.t("rankings.download.equity"),
          field: "equity",
          visible: this.$store.state.selectedRankingFilters.headers.equity,
        },
      ].filter(item => item.visible);
    },
    filename() {
      return this.$i18n.t("rankings.download.filename", {
        currency: this.$i18n.t(`selectors.currencies.${this.$store.state.selectedCurrency}`),
        date: this.$store.state.selectedRankingDate,
      });
    },
  },
  methods: {
    nameFund(e) {
      return this.$i18n.t(`selectors.rankings.fund.fund.${e}`);
    },
    nameAsset1(e) {
      return this.$i18n.t(`selectors.rankings.asset.asset1.${e}`);
    },
    nameAsset2(e) {
      if (e === 0) return null;
      return this.$i18n.t(`selectors.rankings.asset.asset2.${e}`);
    },
    nameTheme(e) {
      return this.$i18n.t(`selectors.rankings.theme.theme.${e}`);
    },
    nameGeography1(e) {
      return this.$i18n.t(`selectors.rankings.geography.geography1.${e}`);
    },
    nameGeography2(e) {
      if (e === 0) return null;
      return this.$i18n.t(`selectors.rankings.geography.geography2.${e}`);
    },
    nameGeography3(e) {
      if (e === 0) return null;
      return this.$i18n.t(`selectors.rankings.geography.geography3.${e}`);
    },
  },
};
</script>

<style scoped></style>
