<template>
  <v-app>
    <div class="main-body">
      <div class="content">
        <router-view />
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  watch: {
    $route(to) {
      document.title = to.meta.title || "Comparador de Fondos";
    },
  },
  methods: {
    notVisibleRoutes() {
      return ["login", "silicon"].includes(this.$route.name);
    },
  },
};
</script>

<style lang="scss">
@import "style/style.scss";
#app {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #4f4f4f;
  background-color: #fff7f1;
}
</style>
