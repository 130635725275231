<template>
  <v-dialog v-model="dialog">
    <template #activator="{ on, attrs }">
      <v-btn
        large
        elevation="0"
        :class="{
          'button-font-size': true,
          'button-gradient': !loading,
          'full-button': true,
        }"
        :disabled="loading"
        :min-height="height"
        :max-height="height"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="popup-card vertical-spacing-mobile horizontal-spacing-mobile">
      <div class="vertical-spacing-mobile">
        <fund-search :loading="loading" @close="close" />
      </div>
      <v-divider />
      <fund-selector :loading="loading" @close="close" />
    </v-card>
  </v-dialog>
</template>

<script>
import FundSelector from "@/components/FundSelector.vue";
import FundSearch from "@/components/FundSearch.vue";

export default {
  name: "MobileFundSelector",
  components: {
    FundSelector,
    FundSearch,
  },
  props: {
    loading: Boolean,
    height: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.popup-card {
  border-radius: 20px !important;
  background: #e2e0e0 !important;
}
</style>
