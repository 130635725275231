<template>
  <div>
    <v-card-title class="card-title">{{ $i18n.t("selectors.fund.title") }}</v-card-title>
    <!-- TODO: implement full list <v-subheader>{{ $i18n.t("selectors.fund.list") }}</v-subheader> -->
    <v-autocomplete
      v-model="category"
      outlined
      rounded
      background-color="white"
      :label="$i18n.t('selectors.fund.labels.category')"
      :filter="filterFunds"
      :items="fundCategories"
      item-text="name"
      item-value="category"
      autocomplete="off"
      single-line
      append-icon="mdi-chevron-down"
      :disabled="loading"
      @change="cleanup(3)"
      :menu-props="{
        rounded: 'lg',
      }"
      color="noHighlight"
    >
      <template #item="data">
        <v-list-item-content :id="`select-category-${data.item.category}`">
          <v-list-item-title>
            {{ `${data.item.name}` }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="fundClass"
      outlined
      rounded
      background-color="white"
      :label="$i18n.t('selectors.fund.labels.class')"
      :filter="filterFunds"
      :items="chosenClasses"
      item-text="name"
      item-value="id"
      autocomplete="off"
      single-line
      append-icon="mdi-chevron-down"
      :disabled="loading || !chosenClasses || !chosenClasses.length"
      @change="cleanup(2)"
      :menu-props="{
        rounded: 'lg',
      }"
      color="noHighlight"
    >
      <template #item="data">
        <v-list-item-content :id="`select-class-${data.item.id}`">
          <v-list-item-title>
            {{ `${data.item.name}` }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="fund"
      outlined
      rounded
      background-color="white"
      :label="$i18n.t('selectors.fund.labels.fund')"
      :filter="filterFunds"
      :items="chosenFunds"
      item-text="name"
      item-value="id"
      autocomplete="off"
      single-line
      append-icon="mdi-chevron-down"
      :disabled="loading || !chosenFunds || !chosenFunds.length"
      @change="cleanup(1)"
      :menu-props="{
        rounded: 'lg',
      }"
      color="noHighlight"
    >
      <template #item="data">
        <v-list-item-content :id="`select-fund-${data.item.id}`">
          <v-list-item-title>
            {{ `${data.item.name}` }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="series"
      outlined
      rounded
      background-color="white"
      :label="$i18n.t('selectors.fund.labels.series')"
      :filter="filterFunds"
      :items="chosenSeries"
      item-text="series"
      item-value="series"
      autocomplete="off"
      single-line
      append-icon="mdi-chevron-down"
      :disabled="loading || !chosenSeries || !chosenSeries.length"
      :menu-props="{
        rounded: 'lg',
      }"
      color="noHighlight"
    >
      <template #item="data">
        <v-list-item-content :id="`select-series-${data.item.series}`">
          <v-list-item-title>
            {{ `${data.item.series}` }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-btn
      rounded
      x-large
      class="btn-shape"
      elevation="0"
      height="50px"
      @click="addFund"
      :disabled="!fund || !series"
      :class="{ 'button-gradient': fund && series }"
      id="add-fund-selector"
    >
      <v-icon>mdi-plus-circle-outline</v-icon>
      <v-spacer />
      <div>{{ $i18n.t("selectors.fund.buttons.add") }}</div>
    </v-btn>
  </div>
</template>

<script>
/* eslint-disable arrow-parens */
import { selectOptions } from "@/mixins/selectOptions";

export default {
  name: "FundSelector",
  mixins: [selectOptions],
  data() {
    return {
      skipQuotas: true,
      category: null,
      fundClass: -1,
      fund: null,
      series: null,
    };
  },
  props: {
    loading: Boolean,
  },
  methods: {
    filterFunds(item, queryText, itemText) {
      const itemName = itemText.toLowerCase();
      const inSentence = word => itemName.includes(word);
      const words = queryText.toLowerCase().split(" ");
      return words.every(inSentence);
    },
    cleanup(value) {
      if (value > 2) {
        this.fundClass = -1;
      }
      if (value > 1) {
        this.fund = null;
      }
      if (value > 0) {
        this.series = null;
      }
    },
    addFund() {
      this.$store.commit("addFund", {
        fundId: this.fund,
        series: this.series,
        category: this.category,
      });
      this.fund = null;
      this.series = null;
      this.$emit("close");
    },
    clearFunds() {
      this.$store.commit("clearFunds");
    },
  },
  computed: {
    chosenClasses() {
      if (this.category) {
        const categories = [];
        this.$store.state.classes[this.category].forEach(cat => {
          categories.push(cat);
          categories.push({ divider: true });
        });
        return categories;
      }
      return [];
    },
    chosenFunds() {
      if (this.category && this.fundClass !== null) {
        const funds = [];
        if (this.fundClass === -1) {
          this.$store.state.funds[this.category].forEach(fnd => {
            funds.push(fnd);
            funds.push({ divider: true });
          });
        } else {
          this.$store.state.funds[this.category]
            .filter(
              // eslint-disable-next-line arrow-parens
              fnd => fnd.classId === this.fundClass,
            )
            .forEach(fnd => {
              funds.push(fnd);
              funds.push({ divider: true });
            });
        }
        return funds;
      }
      return [];
    },
    chosenSeries() {
      if (this.category && this.fund) {
        const series = [];
        this.$store.state.series[this.category][this.fund].forEach(ser => {
          series.push(ser);
          series.push({ divider: true });
        });
        return series;
      }
      return [];
    },
  },
};
</script>

<style scoped>
.card-title {
  font-size: 16px;
  font-weight: bold;
  color: #4f4f4f;
}
.btn-shape {
  color: white;
  font-weight: bold;
  font-size: 12px;
}
</style>
