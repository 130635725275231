<template>
  <v-card rounded elevation="0" id="dva-link" class="adbg" href="https://www.silicon.dvacapital.com/" target="_blank">
    <v-container>
      <v-img :src="require('@/assets/AdSilicon.png')" contain />
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Ad",
  data() {
    return {};
  },
};
</script>

<style scoped>
.adbg {
  background: #FF0079 !important;
  border-radius: 20px !important;
  border-bottom: none !important;
  box-shadow: none !important;
}
</style>
