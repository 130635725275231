import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#DE7600",
        secondary: "#5E5E5E",
        background: "#333333",
        noHighlight: "#9E9E9E",
        checkbox: "#DE7600",
        checkboxMiddle: "#DE7600",
        checkboxInner: "#DE7600",
      },
    },
  },
  lang: {
    locales: { es },
    current: "es",
  },
});
