var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[(_vm.selected)?_c('v-card',{staticClass:"card-modal",attrs:{"rounded":"lg","elevation":"0"}},[_c('div',[_c('v-card-title',[_c('v-spacer'),_c('div',[_vm._v(_vm._s(_vm.selected.name))]),_c('v-spacer')],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$i18n.t("rankings.dialog.id", { fundId: _vm.selected.fundId }))+" ")]),_c('v-row',{staticClass:"my-1",attrs:{"no-gutters":"","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"class-chip",attrs:{"color":_vm.colors[0]}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$i18n.t(("selectors.rankings.fund.fund." + (_vm.selected.category))))+" ")])]}}],null,false,1485320103)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("selectors.rankings.fund.hover")))])])],1),_c('v-row',{staticClass:"my-1",attrs:{"no-gutters":"","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"class-chip",attrs:{"color":_vm.colors[1]}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$i18n.t(("selectors.rankings.asset.asset1." + (_vm.selected.asset1))))+" ")])]}}],null,false,245769926)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("selectors.rankings.asset.hover")))])]),(_vm.selected.asset2 >= 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"class-chip",attrs:{"color":_vm.colors[1]}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$i18n.t(("selectors.rankings.asset.asset2." + (_vm.selected.asset2))))+" ")])]}}],null,false,597912326)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("selectors.rankings.asset.hover")))])]):_vm._e()],1),_c('v-row',{staticClass:"my-1",attrs:{"no-gutters":"","justify":"center"}},[(_vm.selected.theme === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"class-chip",attrs:{"color":_vm.colors[2]}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$i18n.t("selectors.rankings.theme.title"))+" ")])]}}],null,false,1591562799)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("selectors.rankings.theme.hover")))])]):_vm._e()],1),_c('v-row',{staticClass:"my-1",attrs:{"no-gutters":"","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"class-chip",attrs:{"color":_vm.colors[3]}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$i18n.t(("selectors.rankings.geography.geography1." + (_vm.selected.geography1))))+" ")])]}}],null,false,1457569964)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("selectors.rankings.geography.hover")))])]),(_vm.selected.geography2 >= 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"class-chip",attrs:{"color":_vm.colors[3]}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$i18n.t(("selectors.rankings.geography.geography2." + (_vm.selected.geography2))))+" ")])]}}],null,false,2832771500)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("selectors.rankings.geography.hover")))])]):_vm._e(),(_vm.selected.geography3 >= 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"class-chip",attrs:{"color":_vm.colors[3]}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$i18n.t(("selectors.rankings.geography.geography3." + (_vm.selected.geography3))))+" ")])]}}],null,false,1932756652)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("selectors.rankings.geography.hover")))])]):_vm._e()],1),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$i18n.t("rankings.dialog.series")))]),_c('v-list',{class:{
          'horizontal-spacing': !_vm.isMobile,
          'horizontal-spacing-mobile': _vm.isMobile,
        }},_vm._l((_vm.selected.series),function(series,index){return _c('v-list-item',{key:index,style:({
            'background-color': index % 2 === 0 ? '#F2F2F2' : '#FFFFFF',
          })},[_c('div',[_vm._v(_vm._s(series.series))])])}),1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }