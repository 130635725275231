<template>
  <div v-if="standalone">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn icon class="ma-2" v-bind="attrs" v-on="on" @click="clearFunds" id="clear-funds">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $i18n.t("table.buttons.tooltips.clear") }}</span>
    </v-tooltip>
  </div>
  <v-list-item v-else @click="clearFunds">
    <v-list-item-icon>
      <v-icon>mdi-close-circle-outline</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ $i18n.t("table.buttons.tooltips.clear") }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "ClearFunds",
  data() {
    return {};
  },
  props: {
    standalone: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clearFunds() {
      this.$store.commit("clearFunds");
    },
  },
};
</script>

<style scoped></style>
