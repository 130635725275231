<template>
  <v-card rounded class="chart-card card-elevation">
    <div
      v-if="!loading && chartQuotas.length > 0"
      :class="{
        'vertical-spacing horizontal-spacing': !isMedium,
        'vertical-spacing-mobile horizontal-spacing-mobile': isMedium,
      }"
    >
      <v-card-title>
        <div
          class="card-title"
          :style="{
            'font-size': isMobile ? '14px' : '22px',
          }"
        >
          <div class="bold-text">
            {{
              $i18n.t("chart.title", {
                startDate: format_date($store.state.selectedDate.startDate),
                endDate: format_date($store.state.selectedDate.endDate),
                currency: $i18n.t(`selectors.currencies.${$store.state.selectedCurrency}`),
              })
            }}
          </div>
        </div>
      </v-card-title>
      <apexchart type="line" :options="returnOptions" :series="chartQuotas" class="ma-2" height="500"></apexchart>
    </div>
  </v-card>
</template>

<script>
/* eslint-disable arrow-parens */
import es from "@/lang/chart_es";
import colors from "@/helper/colors";

export default {
  name: "OutputChart",
  data() {
    return {
      returnOptions: {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            tools: {
              zoom: `<i aria-hidden="${true}" class="v-icon notranslate mdi mdi-cursor-default "></i>`,
            },
            autoSelected: "pan",
            export: {
              csv: {
                filename: `${this.$store.state.selectedDate.code} - ${this.$store.state.selectedCurrency}`,
                headerCategory: this.$i18n.t("chart.csv.dateColumn"),
                dateFormatter(timestamp) {
                  return timestamp.split(" ")[0];
                },
              },
            },
          },
          fontFamily: "Montserrat",
          locales: [es],
          defaultLocale: "es",
        },
        xaxis: {
          type: "datetime",
          labels: {
            style: {
              fontSize: "0.9em",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "0.9em",
            },
            formatter: value => `${this.format_plot(value, 0)}%`,
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              strokeDashArray: 0,
              borderColor: "#775DD0",
            },
          ],
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        legend: {
          show: false,
          // fontSize: 15,
          // position: "top",
        },
        tooltip: {
          y: {
            formatter: value => `${this.format_plot(value)}%`,
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        colors,
      },
    };
  },
  computed: {
    isMobile() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return true;
      }
      return false;
    },
    isMedium() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        return true;
      }
      return false;
    },
    chartQuotas() {
      return this.$store.state.selectedFunds.map(fund => fund.quotas);
    },
  },
  methods: {
    format_plot(value, digits = 2) {
      return value.toLocaleString("es-CL", {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });
    },
    format_date(value) {
      if (value) {
        const toFormat = new Date(`${value} 12:00:00`);
        const formatter = new Intl.DateTimeFormat("es-CL", { month: "short" });
        const month = formatter.format(toFormat);
        return `${toFormat.getUTCDate()} ${month} ${toFormat.getUTCFullYear()}`;
      }
      return null;
    },
  },
  props: {
    loading: Boolean,
  },
};
</script>

<style scoped>
.chart-card {
  border-radius: 20px !important;
}
.card-title {
  color: #4f4f4f;
}
</style>
