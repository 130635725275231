<template>
  <div
    :class="{
      'vertical-spacing-mobile': true,
    }"
  >
    <v-row no-gutters>
      <v-spacer />
      <currency-selector :loading="loading" :buttonHeight="buttonHeight" />
      <div id="ranking-date-selectors">
        <v-menu offset-y rounded="lg" nudge-bottom="5">
          <template #activator="{ on, attrs }">
            <v-btn
              large
              elevation="0"
              :class="{
                'button-font-size': true,
                'button-gradient': !loading,
                'full-button': true,
              }"
              :min-width="isMobile ? 0 : 180"
              :disabled="loading"
              :min-height="buttonHeight"
              :max-height="buttonHeight"
              v-on="on"
              v-bind="attrs"
              id="ranking-date-selector"
            >
              <v-row no-gutters>
                <div class="date-selector-text">
                  {{ $i18n.t(`selectors.dates.rankings.${isMobile ? "name" : "longName"}.${$store.state.selectedRankingPeriod.code}`) }}
                </div>
                <v-spacer />
                <v-icon>mdi-chevron-down</v-icon>
              </v-row>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(item, index) in possibleDates">
              <v-list-item :key="index * 2" @click="setRankingsPeriod(item)">
                <v-list-item-title>{{ item.longName }}</v-list-item-title>
              </v-list-item>
              <v-divider :key="index * 2 + 1" />
            </template>
          </v-list>
        </v-menu>
      </div>
      <mobile-ranking-filter-selector :loading="loading" :height="buttonHeight" v-if="isMobile" />
      <v-spacer v-if="isMobile" />
    </v-row>
  </div>
</template>

<script>
/* eslint-disable arrow-parens */
import CurrencySelector from "@/components/inner/CurrencySelector.vue";
import MobileRankingFilterSelector from "@/components/rankings/MobileRankingFilterSelector.vue";

export default {
  name: "Selectors",
  components: {
    CurrencySelector,
    MobileRankingFilterSelector,
  },
  data() {
    return {
      buttonHeight: "50px",
      allDates: [
        {
          name: this.$i18n.t("selectors.dates.rankings.name.day"),
          longName: this.$i18n.t("selectors.dates.rankings.longName.day"),
          code: "day",
        },
        {
          name: this.$i18n.t("selectors.dates.rankings.name.month"),
          longName: this.$i18n.t("selectors.dates.rankings.longName.month"),
          code: "month",
        },
        {
          name: this.$i18n.t("selectors.dates.rankings.name.year"),
          longName: this.$i18n.t("selectors.dates.rankings.longName.year"),
          code: "year",
        },
      ],
    };
  },
  props: {
    loading: Boolean,
  },
  computed: {
    possibleDates() {
      return this.allDates.filter(dt => dt.code !== this.$store.state.selectedDate.code);
    },
    isMobile() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return true;
      }
      return false;
    },
  },
  methods: {
    setRankingsPeriod(item) {
      this.$store.commit("setRankingsPeriod", item);
    },
  },
};
</script>

<style scoped>
.button-font-size {
  font-size: 12px;
}
.date-selector-text {
  margin-top: 5px;
}
</style>
