var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-card',{staticClass:"chart-card card-elevation"},[_c('div',{class:{
        'vertical-spacing horizontal-spacing': !_vm.isMedium,
        'vertical-spacing-mobile horizontal-spacing-mobile': _vm.isMedium,
      }},[(_vm.$store.state.selectedFunds.length === 0)?_c('div',[_c('v-card-title',[_vm._v(_vm._s(_vm.$i18n.t("table.empty")))])],1):_c('div',[_c('v-card-title',[_c('div',{staticClass:"card-title bold-text",style:({
              'font-size': _vm.isMobile ? '14px' : '22px',
            })},[_vm._v(" "+_vm._s(_vm.$i18n.t("rankings.title", { currency: _vm.$i18n.t(("selectors.currencies." + (_vm.$store.state.selectedCurrency))), date: _vm.format_date(_vm.$store.state.selectedRankingDate), }))+" ")]),_c('v-spacer'),(!_vm.isMobile)?_c('ranking-download-data',{attrs:{"standalone":true}}):_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('v-list',[_c('ranking-download-data',{attrs:{"standalone":false}})],1)],1)],1)],1),_c('v-card-subtitle',[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_vm._v(_vm._s(_vm.$i18n.t("rankings.subtitle", { funds: _vm.filteredRankings.length })))]),_c('v-spacer')],1)],1),_c('v-data-table',{staticClass:"ma-2 returns-table",attrs:{"headers":_vm.headers,"items":_vm.filteredRankings,"mobile-breakpoint":_vm.breakpoint,"items-per-page":_vm.perPage,"footer-props":{
            showFirstLastPage: true,
            itemsPerPageOptions: [15],
            showCurrentPage: true,
          }},on:{"click:row":_vm.clickRow,"update:items-per-page":_vm.updateItemsPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
          var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPage - 1) * _vm.currentPerPage + index + 1)+" ")]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('div',{staticClass:"bold-text chip-table",style:({ background: _vm.colors[index % 10], width: _vm.isMobile ? '150px' : '250px' })},[_c('div',{staticClass:"name-chip"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.ytd",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'ytd',"tooltip":true}})]}},{key:"item.M1",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'M1',"tooltip":true}})]}},{key:"item.M3",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'M3',"tooltip":true}})]}},{key:"item.M6",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'M6',"tooltip":true}})]}},{key:"item.Y1",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'Y1',"tooltip":true}})]}},{key:"item.Y2",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'Y2',"tooltip":true}})]}},{key:"item.Y3",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'Y3',"tooltip":true}})]}},{key:"item.Y5",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'Y5',"tooltip":true}})]}},{key:"item.Y10",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'Y10',"tooltip":true}})]}},{key:"item.equity",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'equity',"equity":true}})]}}],null,true)})],1)])]),_c('ranking-dialog',{attrs:{"selected":_vm.selected,"dialog":_vm.dialog},on:{"updateDialog":_vm.updateDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }