import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import Rankings from "@/views/Rankings.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        title: "Comparador de Fondos",
      },
    },
    {
      path: "/rankings",
      name: "rankings",
      component: Rankings,
      meta: {
        title: "Rankings",
      },
    },
  ],
});
