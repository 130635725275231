import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueApexCharts from "vue-apexcharts";
import VueAnalytics from "vue-analytics";
import VueApollo from "vue-apollo";
import VueExcelXlsx from "vue-excel-xlsx";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import client from "@/apollo";
import "@/filters";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";

Vue.config.productionTip = false;
const isProd = process.env.NODE_ENV === "production";
Vue.use(VueAxios, axios);
Vue.use(VueAnalytics, {
  id: "UA-68575253-4",
  router,
  autoTracking: {
    exception: true,
  },
  debug: {
    enabled: !isProd,
    sendHitTask: isProd,
  },
});
Vue.use(VueApexCharts);
Vue.use(VueApollo);
Vue.use(VueExcelXlsx);
Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: new VueApollo({
    defaultClient: client,
  }),
  render: h => h(App),
}).$mount("#app");
