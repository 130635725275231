<template>
  <div>
    <v-card-title class="card-title">{{ $i18n.t("selectors.rankings.title") }}</v-card-title>
    <v-expansion-panels accordion :disabled="loading">
      <!-- FUND -->
      <v-expansion-panel class="expansion-bg">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          {{ $i18n.t("selectors.rankings.fund.title") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            :disabled="loading"
            v-model="allFunds"
            color="checkbox"
            :label="$i18n.t(`selectors.rankings.${allFunds ? 'all' : 'none'}`)"
            hide-details
            class="checkbox-margin"
            id="rankings-funds-all"
          />
          <v-divider />
          <v-checkbox
            :disabled="loading"
            v-model="fund.INV"
            color="checkbox"
            :label="$i18n.t(`selectors.rankings.fund.fund.INV`)"
            hide-details
            class="checkbox-margin"
            id="rankings-fund-inv"
          />
          <v-checkbox
            :disabled="loading"
            v-model="fund.MUT"
            color="checkbox"
            :label="$i18n.t(`selectors.rankings.fund.fund.MUT`)"
            hide-details
            class="checkbox-margin"
            id="rankings-fund-mut"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- ASSET -->
      <v-expansion-panel class="expansion-bg">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          {{ $i18n.t("selectors.rankings.asset.title") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            :disabled="loading"
            v-model="allAsset"
            color="checkbox"
            :label="$i18n.t(`selectors.rankings.${allAsset ? 'all' : 'none'}`)"
            hide-details
            class="checkbox-margin"
            id="rankings-asset-all"
          />
          <v-divider />
          <div v-for="(val, index) in asset1" :key="index">
            <v-checkbox
              v-model="asset1[index]"
              color="checkbox"
              :disabled="loading"
              :label="$i18n.t(`selectors.rankings.asset.asset1.${index}`)"
              hide-details
              class="checkbox-margin"
              :id="`rankings-asset1-${$i18n.t(`selectors.rankings.asset.asset1.${index}`)}`"
            />
            <div v-if="asset1[index] && asset1Dependencies[index]">
              <v-checkbox
                v-for="(val, index) in asset1Dependencies[index]"
                :key="index"
                v-model="asset2[val]"
                color="checkboxMiddle"
                :disabled="loading"
                :label="$i18n.t(`selectors.rankings.asset.asset2.${val}`)"
                hide-details
                class="checkbox-margin ml-4"
                :id="`rankings-asset2-${$i18n.t(`selectors.rankings.asset.asset2.${val}`)}`"
              />
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- THEME -->
      <v-expansion-panel class="expansion-bg">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          {{ $i18n.t("selectors.rankings.theme.title") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            :disabled="loading"
            v-model="allTheme"
            color="checkbox"
            :label="$i18n.t(`selectors.rankings.${allTheme ? 'all' : 'none'}`)"
            hide-details
            class="checkbox-margin"
            id="rankings-theme-all"
          />
          <v-divider />
          <v-checkbox
            v-for="(val, index) in theme"
            :key="index"
            :disabled="loading"
            v-model="theme[index]"
            color="checkbox"
            :label="$i18n.t(`selectors.rankings.theme.theme.${index}`)"
            hide-details
            class="checkbox-margin"
            :id="`rankings-theme-${$i18n.t(`selectors.rankings.theme.theme.${index}`)}`"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- GEOGRAPHY -->
      <v-expansion-panel class="expansion-bg">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          {{ $i18n.t("selectors.rankings.geography.title") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            :disabled="loading"
            v-model="allGeography"
            color="checkbox"
            :label="$i18n.t(`selectors.rankings.${allGeography ? 'all' : 'none'}`)"
            hide-details
            class="checkbox-margin"
            id="rankings-geography-all"
          />
          <v-divider />
          <div v-for="(val, index) in geography1" :key="index">
            <v-checkbox
              v-model="geography1[index]"
              color="checkbox"
              :disabled="loading"
              :label="$i18n.t(`selectors.rankings.geography.geography1.${index}`)"
              hide-details
              class="checkbox-margin"
              :id="`rankings-geography-${$i18n.t(`selectors.rankings.geography.geography1.${index}`)}`"
            />
            <div v-if="geography1[index] && geography1Dependencies[index]">
              <div v-for="(val, index) in geography1Dependencies[index]" :key="index">
                <v-checkbox
                  v-model="geography2[val]"
                  color="checkboxMiddle"
                  :disabled="loading"
                  :label="$i18n.t(`selectors.rankings.geography.geography2.${val}`)"
                  hide-details
                  class="checkbox-margin ml-4"
                  :id="`rankings-geography-${$i18n.t(`selectors.rankings.geography.geography2.${val}`)}`"
                />
                <div v-if="geography2[val] && geography2Dependencies[val]">
                  <v-checkbox
                    v-for="(val2, index2) in geography2Dependencies[val]"
                    :key="index2"
                    v-model="geography3[val2]"
                    color="checkboxInner"
                    :disabled="loading"
                    :label="$i18n.t(`selectors.rankings.geography.geography3.${val2}`)"
                    hide-details
                    class="checkbox-margin ml-8"
                    :id="`rankings-geography-${$i18n.t(`selectors.rankings.geography.geography3.${index2}`)}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="expansion-bg">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          {{ $i18n.t("selectors.rankings.headers.title") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            :disabled="loading"
            v-model="allHeaders"
            color="checkbox"
            :label="$i18n.t(`selectors.rankings.${allHeaders ? 'all' : 'none'}`)"
            hide-details
            class="checkbox-margin"
            id="rankings-headers-all"
          />
          <v-divider />
          <v-checkbox
            v-for="(val, index) in Object.keys(headers)"
            :key="index"
            :disabled="loading"
            v-model="headers[val]"
            color="checkbox"
            :label="$i18n.t(`rankings.headers.${val}`)"
            hide-details
            class="checkbox-margin"
            :id="`rankings-headers-${val}`"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- OTHER -->
      <v-expansion-panel class="expansion-bg">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          {{ $i18n.t("selectors.rankings.other.title") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            v-for="(value, index) in equityValues"
            :key="index"
            class="equity-panel"
            v-model.number="equityValues[index]"
            type="number"
            color="checkbox"
            :suffix="
              $i18n.t('selectors.rankings.other.equity.quantity', {
                currency: $i18n.t(`selectors.currencies.${$store.state.selectedCurrency}`),
              })
            "
            outlined
            dense
            hide-details
          >
            <template #prepend>
              <v-checkbox
                :disabled="loading"
                v-model="equityLesserGreater[index]"
                color="checkbox"
                hide-details
                class="checkbox-margin"
                :id="`rankings-equity-filter-${index}`"
              />
              <div>{{ $i18n.t(`selectors.rankings.other.equity.${index}`) }}</div>
            </template>
          </v-text-field>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- BUTTONS -->
    <v-row no-gutters justify="center">
      <v-btn
        rounded
        x-large
        :class="{
          'btn-shape vertical-spacing-mobile my-1': true,
          'button-gradient': !loading,
        }"
        elevation="0"
        height="50px"
        :disabled="loading"
        @click="applyFilters"
        id="rankings-apply-filter"
      >
        {{ $i18n.t("selectors.rankings.buttons.filter") }}
      </v-btn>
      <v-btn
        rounded
        x-large
        :class="{
          'btn-shape vertical-spacing-mobile my-1': true,
          'button-gradient': !loading,
        }"
        elevation="0"
        height="50px"
        :disabled="loading"
        @click="clearFilters"
        id="rankings-clear-filter"
      >
        {{ $i18n.t("selectors.rankings.buttons.reset") }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
export default {
  name: "RankingFilterSelector",
  props: {
    loading: Boolean,
  },
  data() {
    return {
      allFunds: true,
      allAsset: true,
      allTheme: true,
      allHeaders: false,
      allGeography: true,
      fund: { INV: true, MUT: true },
      asset1: [false, false, true, false, false, false],
      asset2: [false, true, true, false, false, false, false, false],
      theme: Array(2).fill(true),
      geography1: Array(4).fill(true),
      geography2: Array(10).fill(true),
      geography3: Array(8).fill(true),
      headers: { ytd: true, M1: true, M3: true, M6: true, Y1: true, Y2: false, Y3: true, Y5: true, Y10: false, equity: true },
      asset1Dependencies: {
        0: null,
        1: null,
        2: [1, 2],
        3: [3, 4],
        4: null,
        5: [5, 6, 7],
      },
      geography1Dependencies: {
        1: null,
        2: [1, 2, 3, 4, 5, 6],
        3: [7, 8, 9],
      },
      geography2Dependencies: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: [1, 2, 3, 4],
        8: [5, 6, 7],
        9: null,
      },
      equityLesserGreater: Array(2).fill(false),
      equityValues: Array(2).fill(0.0),
    };
  },
  computed: {},
  watch: {
    allFunds(newv) {
      this.fund.INV = newv;
      this.fund.MUT = newv;
    },
    allAsset(newv) {
      this.asset1.fill(newv);
      this.asset2.fill(newv);
    },
    allTheme(newv) {
      this.theme.fill(newv);
    },
    allHeaders(newv) {
      Object.keys(this.headers).forEach(key => {
        this.headers[key] = newv;
      });
    },
    allGeography(newv) {
      this.geography1.fill(newv);
      this.geography2.fill(newv);
      this.geography3.fill(newv);
    },
  },
  methods: {
    applyFilters() {
      this.$store.commit("updateRankingFilters", {
        fund: { ...this.fund },
        asset1: this.asset1.slice(),
        asset2: this.asset2.slice(),
        theme: this.theme.slice(),
        geography1: this.geography1.slice(),
        geography2: this.geography2.slice(),
        geography3: this.geography3.slice(),
        headers: { ...this.headers },
        equityLesserGreater: this.equityLesserGreater.slice(),
        equityValues: this.equityValues.map(item => item * 1000000),
      });
    },
    clearFilters() {
      this.$store.commit("resetRankingFilters");
      this.allFunds = true;
      this.allAsset = true;
      this.allTheme = true;
      this.allGeography = true;
      this.headers = { ytd: true, M1: true, M3: true, M6: true, Y1: true, Y2: false, Y3: true, Y5: true, Y10: false, equity: true };
      this.equityLesserGreater = Array(2).fill(false);
      this.equityValues = Array(2).fill(0.0);
    },
  },
};
</script>

<style scoped>
.card-title {
  font-size: 16px;
  font-weight: bold;
  color: #4f4f4f;
}
.btn-shape {
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin-left: 8px;
  margin-right: 8px;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.expansion-bg {
  background: #e2e0e0 !important;
}
.equity-panel {
  font-size: 14px;
}
</style>
