import ApolloClient, { InMemoryCache } from "apollo-boost";

export default new ApolloClient({
  uri: process.env.VUE_APP_GRAPH,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  request: async operation => {
    operation.setContext({
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
});
