<template>
  <v-dialog v-model="dialog" max-width="700">
    <template #activator="{ on, attrs }">
      <v-btn
        large
        class="right-currency"
        elevation="0"
        :class="{
          'button-gradient': !loading && $store.state.selectedDate.code === 'custom',
          'button-inactive': !loading && $store.state.selectedDate.code !== 'custom',
        }"
        min-width="180"
        :height="buttonHeight"
        :disabled="loading"
        v-bind="attrs"
        v-on="on"
        id="open-custom-date"
      >
        <v-row no-gutters>
          <div class="date-selector-text">{{ $i18n.t("selectors.dates.buttons.range") }}</div>
          <v-spacer />
          <v-icon>mdi-calendar-blank-outline</v-icon>
        </v-row>
      </v-btn>
    </template>
    <v-card rounded="lg" elevation="0" class="card-modal">
      <v-container>
        <p>
          {{ tempStartDate | formatDate }}
          <v-icon>mdi-arrow-right</v-icon>
          {{ tempEndDate | formatDate }}
        </p>
        <v-divider />

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <v-date-picker
              v-model="tempStartDate"
              class="picker-gradient"
              min="2005-01-01"
              :max="maxDate"
              first-day-of-week="1"
              no-title
              color="primary"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <v-date-picker
              v-model="tempEndDate"
              class="picker-gradient"
              min="2005-01-01"
              :max="maxDate"
              first-day-of-week="1"
              no-title
              color="primary"
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-spacer />
          <v-btn rounded large height="50px" @click="updateDates" class="button-gradient btn-shape" id="choose-custom-date">{{
            $i18n.t("selectors.dates.buttons.confirm")
          }}</v-btn>
          <v-spacer />
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable arrow-parens */
const moment = require("moment-timezone");

export default {
  name: "CustomDateSelector",
  data() {
    return {
      dialog: false,
      tempStartDate: this.$store.state.selectedDate.startDate,
      tempEndDate: this.$store.state.selectedDate.endDate,
      maxDate: moment
        .tz(moment(), "America/Santiago")
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
    };
  },
  props: {
    loading: Boolean,
    buttonHeight: {
      type: String,
      default: "50px",
    },
  },
  methods: {
    updateDates() {
      this.$store.commit("setDates", {
        name: this.$i18n.t("selectors.dates.buttons.name.custom"),
        longName: this.$i18n.t("selectors.dates.buttons.longName.custom"),
        code: "custom",
        startDate: this.tempStartDate,
        endDate: this.tempEndDate,
      });
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      this.tempStartDate = this.$store.state.selectedDate.startDate;
      this.tempEndDate = this.$store.state.selectedDate.endDate;
    },
  },
};
</script>

<style scoped>
.card-modal {
  border-bottom: none !important;
  box-shadow: none !important;
}
.date-header {
  text-align: start;
}
.btn-shape {
  margin-top: 4px;
  font-weight: bold;
  font-size: 12px;
}
.date-selector-text {
  margin-top: 5px;
  font-size: 12px;
}
</style>
