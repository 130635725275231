const rearrange = values => {
  values.sort((a, b) => {
    if (a.fundId > b.fundId) {
      return 1;
    }
    if (a.fundId === b.fundId) {
      if (a.series > b.series) {
        return 1;
      }
      return -1;
    }
    return -1;
  });
};

export default {
  setFunds(state, payload) {
    state.funds.INV = payload.INV.funds.sort((a, b) => (a.name < b.name ? -1 : 1));
    state.classes.INV = [
      {
        name: "Todas",
        id: -1,
      },
      ...payload.INV.classes.sort((a, b) => (a.name < b.name ? -1 : 1)),
    ];
    state.series.INV = payload.INV.series;
    state.funds.MUT = payload.MUT.funds.sort((a, b) => (a.name < b.name ? -1 : 1));
    state.classes.MUT = [
      {
        name: "Todas",
        id: -1,
      },
      ...payload.MUT.classes.sort((a, b) => (a.name < b.name ? -1 : 1)),
    ];
    state.series.MUT = payload.MUT.series;
  },
  addFund(state, payload) {
    state.selectedFunds.push({
      fundId: payload.fundId,
      series: payload.series,
      category: payload.category,
      name: null,
      custom: null,
      M1: null,
      M3: null,
      M6: null,
      Y1: null,
      Y2: null,
      Y3: null,
      Y5: null,
      Y10: null,
      0: null,
      equity: null,
      quotas: { name: null, data: [] },
      barQuotas: { name: null, data: [] },
    });
    rearrange(state.selectedFunds);
  },
  addShareLink(state, payload) {
    state.selectedFunds = payload.funds.map(fund => ({
      fundId: fund.fundId,
      series: fund.series,
      category: fund.category,
      name: null,
      custom: null,
      M1: null,
      M3: null,
      M6: null,
      Y1: null,
      Y2: null,
      Y3: null,
      Y5: null,
      Y10: null,
      0: null,
      equity: null,
      quotas: { name: null, data: [] },
      barQuotas: { name: null, data: [] },
    }));
    if (payload.date.startDate && payload.date.endDate) {
      state.selectedDate = payload.date;
    }
    if (payload.currency) {
      state.selectedCurrency = payload.currency;
    }
    rearrange(state.selectedFunds);
  },
  removeFund(state, payload) {
    state.selectedFunds = state.selectedFunds.filter(
      fund => !(fund.fundId === payload.fundId && fund.series === payload.series && fund.category === payload.category),
    );
  },
  setDates(state, payload) {
    state.selectedDate = payload;
  },
  setCurrency(state, payload) {
    state.selectedCurrency = payload;
  },
  setQuotas(state, payload) {
    state.selectedFunds.forEach(sf => {
      sf.custom = null;
      sf.quotas = { name: sf.name, data: [] };
      sf.barQuotas = { name: sf.name, data: [...sf.barQuotas.data.slice(Math.max(sf.barQuotas.data.length - 4, 0))] };
    });
    payload.output.forEach(fund => {
      const exists = state.selectedFunds.find(sf => sf.fundId === fund.fundId && sf.series === fund.series && sf.category === fund.category);
      exists.name = fund.name;
      if (fund.startExists) {
        exists.custom = fund.maximum;
      } else {
        exists.custom = null;
      }
      exists.quotas = {
        name: fund.name,
        data: fund.data,
      };
      exists.barQuotas = {
        name: fund.name,
        data: [
          {
            x: payload.customName,
            y: exists.custom,
          },
          ...exists.barQuotas.data.slice(Math.max(exists.barQuotas.data.length - 4, 0)),
        ],
      };
    });
  },
  setLongTermQuotas(state, payload) {
    payload.output.forEach(fund => {
      const exists = state.selectedFunds.find(sf => sf.fundId === fund.fundId && sf.series === fund.series && sf.category === fund.category);
      exists.name = fund.name;
      exists.M1 = fund.M1;
      exists.M3 = fund.M3;
      exists.M6 = fund.M6;
      exists.Y1 = fund.Y1;
      exists.Y2 = fund.Y2;
      exists.Y3 = fund.Y3;
      exists.Y5 = fund.Y5;
      exists.Y10 = fund.Y10;
      exists.equity = fund.equity;
      exists.barQuotas.name = fund.name;
      if (exists.barQuotas.data && exists.barQuotas.data.length) {
        exists.barQuotas.data = [exists.barQuotas.data[0], ...fund.barQuotas.data];
      } else {
        exists.barQuotas.data = fund.barQuotas.data;
      }
    });
  },
  clearFunds(state) {
    state.selectedFunds = [];
  },
  setRankingsPeriod(state, payload) {
    state.selectedRankingPeriod = payload;
  },
  setRankings(state, payload) {
    state.selectedRanking = payload.output;
    state.selectedRankingDate = payload.date;
  },
  updateRankingFilters(state, payload) {
    state.selectedRankingFilters = payload;
  },
  resetRankingFilters(state) {
    state.selectedRankingFilters.fund = { INV: true, MUT: true };
    state.selectedRankingFilters.asset1 = Array(6).fill(true);
    state.selectedRankingFilters.asset2 = Array(8).fill(true);
    state.selectedRankingFilters.theme = Array(2).fill(true);
    state.selectedRankingFilters.geography1 = Array(4).fill(true);
    state.selectedRankingFilters.geography2 = Array(10).fill(true);
    state.selectedRankingFilters.geography3 = Array(8).fill(true);
    state.selectedRankingFilters.equityLesserGreater = Array(2).fill(false);
    state.selectedRankingFilters.equityValues = Array(2).fill(0.0);
  },
};
