<template>
  <div>
    <div v-if="loading">
      <v-progress-linear indeterminate color="primary" />
    </div>
    <v-card v-else class="chart-card card-elevation">
      <div
        :class="{
          'vertical-spacing horizontal-spacing': !isMedium,
          'vertical-spacing-mobile horizontal-spacing-mobile': isMedium,
        }"
      >
        <div v-if="$store.state.selectedFunds.length === 0">
          <v-card-title>{{ $i18n.t("table.empty") }}</v-card-title>
        </div>
        <div v-else>
          <v-card-title>
            <div
              class="card-title bold-text"
              :style="{
                'font-size': isMobile ? '14px' : '22px',
              }"
            >
              {{ $i18n.t("table.title", { currency: $i18n.t(`selectors.currencies.${$store.state.selectedCurrency}`) }) }}
            </div>
            <v-spacer />
            <div v-if="!isMobile">
              <v-btn
                :class="{
                  'left-button': true,
                  'button-inactive': !table,
                  'button-active': table,
                }"
                max-width="50px"
                height="40px"
                elevation="0"
                @click="table = true"
                id="overview-view-table"
              >
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-btn>
              <v-btn
                :class="{
                  'right-button': true,
                  'button-inactive': table,
                  'button-active': !table,
                }"
                max-width="50px"
                height="40px"
                elevation="0"
                @click="table = false"
                id="overview-view-chart"
              >
                <v-icon>mdi-poll</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn icon class="ma-2" v-on="on" v-bind="attrs">
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="table">
                    <v-list-item-icon>
                      <v-icon>mdi-poll</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title @click="table = false">{{ $i18n.t("table.mobile.chart") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-else>
                    <v-list-item-icon>
                      <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title @click="table = true">{{ $i18n.t("table.mobile.table") }}</v-list-item-title>
                  </v-list-item>
                  <download-data :standalone="false" />
                  <clear-funds :standalone="false" />
                </v-list>
              </v-menu>
            </div>
            <v-divider vertical v-if="!isMobile" />
            <download-data :standalone="true" v-if="!isMobile" />
            <v-divider vertical v-if="!isMobile" />
            <clear-funds :standalone="true" v-if="!isMobile" />
          </v-card-title>
          <div v-if="table">
            <v-data-table
              class="ma-2 returns-table"
              :headers="headers"
              :items="$store.state.selectedFunds"
              :items-per-page="perPage"
              hide-default-footer
              :mobile-breakpoint="breakpoint"
            >
              <template #[`item.0`]="{ item }">
                <v-btn icon @click="removeFund(item)">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </template>
              <template #[`item.name`]="{ item, index }">
                <div class="bold-text chip-table" :style="{ background: colors[index % 10], width: isMobile ? '150px' : '330px' }">
                  <div class="name-chip">{{ item.name }}</div>
                </div>
              </template>
              <template #[`item.custom`]="{ item, index }">
                <table-chip :item="item" :index="index" :field="'custom'" />
              </template>
              <template #[`item.Y1`]="{ item, index }">
                <table-chip :item="item" :index="index" :field="'Y1'" />
              </template>
              <template #[`item.Y2`]="{ item, index }">
                <table-chip :item="item" :index="index" :field="'Y2'" />
              </template>
              <template #[`item.Y3`]="{ item, index }">
                <table-chip :item="item" :index="index" :field="'Y3'" />
              </template>
              <template #[`item.Y5`]="{ item, index }">
                <table-chip :item="item" :index="index" :field="'Y5'" />
              </template>
              <template #[`item.Y10`]="{ item, index }">
                <table-chip :item="item" :index="index" :field="'Y10'" />
              </template>
              <template #[`item.equity`]="{ item, index }">
                <table-chip :item="item" :index="index" :field="'equity'" :equity="true" />
              </template>
            </v-data-table>
          </div>
          <div v-else>
            <apexchart type="bar" :options="barOptions" :series="barQuotas" height="300"></apexchart>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable arrow-parens */
import DownloadData from "@/components/DownloadData.vue";
import ClearFunds from "@/components/ClearFunds.vue";
import TableChip from "@/components/TableChip.vue";
import es from "@/lang/chart_es";
import colors from "@/helper/colors";

export default {
  name: "OutputTable",
  components: {
    DownloadData,
    ClearFunds,
    TableChip,
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      colors,
      table: true,
      breakpoint: 1,
      barOptions: {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          fontFamily: "Montserrat",
          locales: [es],
          defaultLocale: "es",
        },
        xaxis: {
          type: "category",
        },
        yaxis: {
          labels: {
            formatter: value => `${this.format_plot(value, 0)}%`,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: value => `${this.format_plot(value)}%`,
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 300,
              },
            },
          },
        ],
        colors,
      },
    };
  },
  computed: {
    perPage() {
      return this.isMobile ? 25 : 100;
    },
    headers() {
      return [
        {
          text: null,
          value: "0",
          sortable: false,
        },
        {
          text: this.$i18n.t("table.headers.fund"),
          value: "name",
          align: "left",
        },
        {
          text: this.$i18n.t(`table.headers.${this.$store.state.selectedDate.code}`),
          value: "custom",
          align: "right",
        },
        {
          text: this.$i18n.t("table.headers.Y1"),
          value: "Y1",
          align: "right",
        },
        {
          text: this.$i18n.t("table.headers.Y2"),
          value: "Y2",
          align: "right",
        },
        {
          text: this.$i18n.t("table.headers.Y3"),
          value: "Y3",
          align: "right",
        },
        {
          text: this.$i18n.t("table.headers.Y5"),
          value: "Y5",
          align: "right",
        },
        {
          text: this.$i18n.t("table.headers.Y10"),
          value: "Y10",
          align: "right",
        },
        {
          text: this.$i18n.t("table.headers.equity"),
          value: "equity",
          align: "right",
        },
      ];
    },
    barQuotas() {
      return this.$store.state.selectedFunds.map(fund => fund.barQuotas);
    },
    isMobile() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return true;
      }
      return false;
    },
    isMedium() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        return true;
      }
      return false;
    },
  },
  methods: {
    removeFund(fund) {
      this.$store.commit("removeFund", fund);
    },
    format_plot(value, digits = 2) {
      return value.toLocaleString("es-CL", {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });
    },
  },
};
</script>

<style scoped>
.chart-card {
  border-radius: 20px !important;
}
.card-title {
  color: #4f4f4f;
}
.left-button {
  border-radius: 80px 0px 0px 80px;
  margin-top: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  margin-right: 2px;
}
.right-button {
  border-radius: 0px 80px 80px 0px;
  margin-top: 8px;
  margin-left: 2px;
  margin-bottom: 8px;
  margin-right: 8px;
}
.chip-table {
  color: white;
  text-align: center;
  border-radius: 80px;
}
.name-chip {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
}
</style>
