var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item[_vm.field])?_c('div',[(_vm.tooltip)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({class:{
            'bold-text': !_vm.equity,
            greenFont: _vm.item[_vm.field] > 0 && !_vm.equity,
            redFont: _vm.item[_vm.field] < 0 && !_vm.equity,
          },attrs:{"color":_vm.index % 2 === 0 ? '#F2F2F2' : '#FFFFFF'}},'v-chip',attrs,false),on),[(!_vm.equity)?_c('div',[_vm._v(_vm._s(_vm._f("formatPercentages")(_vm.item[_vm.field])))]):_c('div',[_vm._v(_vm._s(_vm._f("formatEquity")(_vm.item[_vm.field])))])])]}}],null,false,3969551428)},[_c('span',[_vm._v(_vm._s(_vm.item[(_vm.field + "series")]))])]):_c('v-chip',{class:{
        'bold-text': !_vm.equity,
        greenFont: _vm.item[_vm.field] > 0 && !_vm.equity,
        redFont: _vm.item[_vm.field] < 0 && !_vm.equity,
      },attrs:{"color":_vm.index % 2 === 0 ? '#F2F2F2' : '#FFFFFF'}},[(!_vm.equity)?_c('div',[_vm._v(_vm._s(_vm._f("formatPercentages")(_vm.item[_vm.field])))]):_c('div',[_vm._v(_vm._s(_vm._f("formatEquity")(_vm.item[_vm.field])))])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }