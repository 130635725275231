<template>
  <div v-if="standalone">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn icon class="ma-2" v-bind="attrs" v-on="on" id="download-funds">
          <vue-excel-xlsx :data="$store.state.selectedFunds" :columns="columns" :filename="filename" :sheetname="'workbook'">
            <v-icon>mdi-download</v-icon>
          </vue-excel-xlsx>
        </v-btn>
      </template>
      <span>{{ $i18n.t("table.buttons.tooltips.download") }}</span>
    </v-tooltip>
  </div>
  <vue-excel-xlsx :data="$store.state.selectedFunds" :columns="columns" :filename="filename" :sheetname="'workbook'" v-else>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-download</v-icon>
      </v-list-item-icon>
      <v-list-item-title>{{ $i18n.t("table.buttons.tooltips.download") }}</v-list-item-title>
    </v-list-item>
  </vue-excel-xlsx>
</template>

<script>
export default {
  name: "DownloadData",
  data() {
    return {};
  },
  props: {
    standalone: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    columns() {
      return [
        {
          label: this.$i18n.t("rankings.download.id"),
          field: "fundId",
        },
        {
          label: this.$i18n.t("table.download.name"),
          field: "name",
        },
        {
          label: `${this.$store.state.selectedDate.startDate} - ${this.$store.state.selectedDate.endDate}`,
          field: "custom",
        },
        {
          label: this.$i18n.t("table.download.M1"),
          field: "M1",
        },
        {
          label: this.$i18n.t("table.download.M3"),
          field: "M3",
        },
        {
          label: this.$i18n.t("table.download.M6"),
          field: "M6",
        },
        {
          label: this.$i18n.t("table.download.Y1"),
          field: "Y1",
        },
        {
          label: this.$i18n.t("table.download.Y2"),
          field: "Y2",
        },
        {
          label: this.$i18n.t("table.download.Y3"),
          field: "Y3",
        },
        {
          label: this.$i18n.t("table.download.Y5"),
          field: "Y5",
        },
        {
          label: this.$i18n.t("table.download.Y10"),
          field: "Y10",
        },
        {
          label: this.$i18n.t("table.download.equity"),
          field: "equity",
        },
      ];
    },
    filename() {
      return this.$i18n.t("table.download.filename", {
        currency: this.$store.state.selectedCurrency,
        startDate: this.$store.state.selectedDate.startDate,
        endDate: this.$store.state.selectedDate.endDate,
      });
    },
  },
};
</script>

<style scoped></style>
