// eslint-disable-next-line import/prefer-default-export
export const selectOptions = {
  data() {
    return {
      fundCategories: [
        { name: "Fondos de Inversión", category: "INV" },
        { divider: true },
        { name: "Fondos Mutuos", category: "MUT" },
        { divider: true },
        { name: "AFP", category: "AFP" },
        { divider: true },
        { name: "Índices", category: "IND" },
      ],
      fundEnum: Object.freeze({
        FI: 1,
        FM: 2,
        AFP: 3,
        Index: 4,
      }),
      currencyOptions: [
        { name: "CLP", value: "CLP" },
        { name: "USD", value: "USD" },
        // { name: "Original", value: "Original" },
      ],
      afpOptions: [
        { name: "PLANVITAL", id: "1" },
        // { name: "Summa Bansander", id: 2 },
        { name: "MODELO", id: "3" },
        { name: "PROVIDA", id: "4" },
        // { name: "Magister", id: 5 },
        { name: "HABITAT", id: "6" },
        // { name: "Santa María", id: 7 },
        { name: "CUPRUM", id: "8" },
        { name: "CAPITAL", id: "9" },
        // { name: "Bansander", id: 10 },
        { name: "UNO", id: "11" },
      ],
      afpFundOptions: [{ name: "A" }, { name: "B" }, { name: "C" }, { name: "D" }, { name: "E" }],
    };
  },
};
