var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"currency-selectors"}},[(!_vm.isMobile)?_c('div',_vm._l((_vm.currencies),function(item,index){return _c('v-btn',{key:index,class:{
        'button-font-size': true,
        'button-gradient': !_vm.loading && _vm.$store.state.selectedCurrency === item,
        'button-inactive': !_vm.loading && _vm.$store.state.selectedCurrency !== item,
        'left-currency': index % 3 === 0,
        'middle-currency': index % 3 === 1,
        'right-currency': index % 3 === 2,
      },attrs:{"large":"","elevation":"0","width":"91","id":("currency-" + item),"height":_vm.buttonHeight,"disabled":_vm.loading},on:{"click":function($event){return _vm.setCurrency(item)}}},[_vm._v(" "+_vm._s(_vm.$i18n.t(("selectors.currencies." + item)))+" ")])}),1):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"full-button",class:{
          'button-font-size': true,
          'button-gradient': !_vm.loading,
        },attrs:{"large":"","elevation":"0","disabled":_vm.loading,"min-height":_vm.buttonHeight,"max-height":_vm.buttonHeight,"id":"mobile-currency"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$store.state.selectedCurrency)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.possibleCurrencies),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.setCurrency(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$i18n.t(("selectors.currencies." + item))))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }