import gql from "graphql-tag";

export const allFunds = () => gql`
  query getFunds {
    funds(active: true) {
      fundId
      category
      name
      classId
      className
      series {
        series
      }
    }
  }
`;

export const getQuotas = () => gql`
  query getQuotas($funds: [QuotaInput]!, $dates: DateInput!, $currency: String!) {
    quotas(funds: $funds, dates: $dates, currency: $currency) {
      date
      category
      fundId
      series
      currency
      adjustedReturn
      netEquity
      fund {
        name
      }
    }
  }
`;

export const getLongTermQuotas = () => gql`
  query getLongTermQuotas($funds: [QuotaInput]!, $endDate: Date!, $currency: String!) {
    longTermQuotas(funds: $funds, endDate: $endDate, currency: $currency) {
      date
      category
      fundId
      series
      currency
      adjustedReturn
      netEquity
      longTermPeriod
      fund {
        name
      }
    }
  }
`;

export const getRankings = () => gql`
  query getRankings($period: String!, $currency: String!) {
    rankings(period: $period, currency: $currency, grouped: true) {
      category
      fundId
      series
      date
      currency
      period
      ytd
      month1
      month3
      month6
      year1
      year2
      year3
      year5
      year10
      ytdSeries
      month1Series
      month3Series
      month6Series
      year1Series
      year2Series
      year3Series
      year5Series
      year10Series
      equity
      fund {
        name
        asset1Id
        asset2Id
        themeId
        geography1Id
        geography2Id
        geography3Id
      }
    }
  }
`;
