<template>
  <v-dialog v-model="dialog">
    <template #activator="{ on, attrs }">
      <v-btn
        large
        elevation="0"
        :class="{
          'button-font-size': true,
          'button-gradient': !loading,
          'full-button': true,
        }"
        :disabled="loading"
        :min-height="height"
        :max-height="height"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon>mdi-filter-plus</v-icon>
      </v-btn>
    </template>
    <v-card class="popup-card vertical-spacing-mobile horizontal-spacing-mobile">
      <ranking-filter-selector :loading="loading" @close="close" />
    </v-card>
  </v-dialog>
</template>

<script>
import RankingFilterSelector from "@/components/rankings/RankingFilterSelector.vue";

export default {
  name: "MobileRankingFilterSelector",
  components: {
    RankingFilterSelector,
  },
  props: {
    loading: Boolean,
    height: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.popup-card {
  border-radius: 20px !important;
  background: #e2e0e0 !important;
}
</style>
