const moment = require("moment-timezone");

export default {
  funds: {
    INV: [],
    MUT: [],
    AFP: [
      { name: "CAPITAL", id: 9 },
      { name: "CUPRUM", id: 8 },
      { name: "HABITAT", id: 6 },
      { name: "MODELO", id: 3 },
      { name: "PLANVITAL", id: 1 },
      { name: "PROVIDA", id: 4 },
      { name: "UNO", id: 11 },
    ],
    IND: [{ name: "IPSA", id: 1 }],
  },
  classes: {
    INV: [],
    MUT: [],
    AFP: [{ name: "Todas", id: -1 }],
    IND: [{ name: "Todas", id: -1 }],
  },
  series: {
    INV: {},
    MUT: {},
    AFP: {
      1: [{ series: "A" }, { series: "B" }, { series: "C" }, { series: "D" }, { series: "E" }],
      3: [{ series: "A" }, { series: "B" }, { series: "C" }, { series: "D" }, { series: "E" }],
      4: [{ series: "A" }, { series: "B" }, { series: "C" }, { series: "D" }, { series: "E" }],
      6: [{ series: "A" }, { series: "B" }, { series: "C" }, { series: "D" }, { series: "E" }],
      8: [{ series: "A" }, { series: "B" }, { series: "C" }, { series: "D" }, { series: "E" }],
      9: [{ series: "A" }, { series: "B" }, { series: "C" }, { series: "D" }, { series: "E" }],
      11: [{ series: "A" }, { series: "B" }, { series: "C" }, { series: "D" }, { series: "E" }],
    },
    IND: { 1: [{ series: "UNICA" }] },
  },
  selectedFunds: [
    {
      fundId: 9118,
      series: "A",
      category: "INV",
      name: null,
      custom: null,
      M1: null,
      M3: null,
      M6: null,
      Y1: null,
      Y2: null,
      Y3: null,
      Y5: null,
      Y10: null,
      0: null,
      equity: null,
      quotas: { name: null, data: [] },
      barQuotas: { name: null, data: [] },
    },
    {
      fundId: 9395,
      series: "A",
      category: "INV",
      name: null,
      custom: null,
      M1: null,
      M3: null,
      M6: null,
      Y1: null,
      Y2: null,
      Y3: null,
      Y5: null,
      Y10: null,
      0: null,
      equity: null,
      quotas: { name: null, data: [] },
      barQuotas: { name: null, data: [] },
    },
    {
      fundId: 9725,
      series: "C",
      category: "INV",
      name: null,
      custom: null,
      M1: null,
      M3: null,
      M6: null,
      Y1: null,
      Y2: null,
      Y3: null,
      Y5: null,
      Y10: null,
      0: null,
      equity: null,
      quotas: { name: null, data: [] },
      barQuotas: { name: null, data: [] },
    },
    {
      fundId: 9937,
      series: "A",
      category: "INV",
      name: null,
      custom: null,
      M1: null,
      M3: null,
      M6: null,
      Y1: null,
      Y2: null,
      Y3: null,
      Y5: null,
      Y10: null,
      0: null,
      equity: null,
      quotas: { name: null, data: [] },
      barQuotas: { name: null, data: [] },
    },
  ],
  selectedRanking: [],
  selectedDate: {
    name: "YTD",
    longName: "YTD (año al dia)",
    code: "YTD",
    startDate: moment
      .tz(moment(), "America/Santiago")
      .subtract(1, "years")
      .endOf("year")
      .format("YYYY-MM-DD"),
    endDate: moment
      .tz(moment(), "America/Santiago")
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
  },
  selectedRankingPeriod: {
    name: "Cierre último mes",
    longName: "Cierre último mes",
    code: "month",
  },
  selectedRankingDate: null,
  selectedRankingFilters: {
    fund: { INV: true, MUT: true },
    asset1: [false, false, true, false, false, false],
    asset2: [false, true, true, false, false, false, false, false],
    theme: Array(2).fill(true),
    geography1: Array(4).fill(true),
    geography2: Array(10).fill(true),
    geography3: Array(8).fill(true),
    headers: {
      ytd: true,
      M1: true,
      M3: true,
      M6: true,
      Y1: true,
      Y2: false,
      Y3: true,
      Y5: true,
      Y10: false,
      equity: true,
    },
    equityLesserGreater: Array(2).fill(false),
    equityValues: Array(2).fill(0.0),
  },
  selectedCurrency: "CLP",
  longTermDate: moment
    .tz(moment(), "America/Santiago")
    .subtract(1, "days")
    .format("YYYY-MM-DD"),
};
