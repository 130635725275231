// eslint-disable-next-line import/prefer-default-export
export const nameHandlers = {
  methods: {
    capitalizeName(name) {
      return name
        .toLowerCase()
        .split(" ")
        .map(word => {
          if (word === "dva") {
            return "DVA";
          }
          return word.charAt(0).toUpperCase() + word.substring(1);
        })
        .join(" ");
    },
    formatName(name) {
      return this.capitalizeName(name)
        .replace(/Fondo De Inversión|Fondo De Inversion|Fondo Inversión|Fondo Inversion|Fondo de Inv./, "FI")
        .replace(/Fondo Mutuo/, "FM");
    },
  },
};
