import Vue from "vue";
import VueI18n from "vue-i18n";
import es from "@/lang/es.json";
// import en from "@/lang/en.json";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "es",
  fallbackLocale: "es",
  messages: {
    es,
    // en,
  },
});
