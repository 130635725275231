var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'vertical-spacing-mobile': true,
  }},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('currency-selector',{attrs:{"loading":_vm.loading,"buttonHeight":_vm.buttonHeight}}),_c('div',{attrs:{"id":"ranking-date-selectors"}},[_c('v-menu',{attrs:{"offset-y":"","rounded":"lg","nudge-bottom":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{
              'button-font-size': true,
              'button-gradient': !_vm.loading,
              'full-button': true,
            },attrs:{"large":"","elevation":"0","min-width":_vm.isMobile ? 0 : 180,"disabled":_vm.loading,"min-height":_vm.buttonHeight,"max-height":_vm.buttonHeight,"id":"ranking-date-selector"}},'v-btn',attrs,false),on),[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"date-selector-text"},[_vm._v(" "+_vm._s(_vm.$i18n.t(("selectors.dates.rankings." + (_vm.isMobile ? "name" : "longName") + "." + (_vm.$store.state.selectedRankingPeriod.code))))+" ")]),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]}}])},[_c('v-list',[_vm._l((_vm.possibleDates),function(item,index){return [_c('v-list-item',{key:index * 2,on:{"click":function($event){return _vm.setRankingsPeriod(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.longName))])],1),_c('v-divider',{key:index * 2 + 1})]})],2)],1)],1),(_vm.isMobile)?_c('mobile-ranking-filter-selector',{attrs:{"loading":_vm.loading,"height":_vm.buttonHeight}}):_vm._e(),(_vm.isMobile)?_c('v-spacer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }