import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import initialState from "@/store/state";
import mutations from "@/store/mutations";

const handleFunds = funds =>
  // eslint-disable-next-line implicit-arrow-linebreak
  funds.map(fund => ({
    fundId: fund.fundId,
    series: fund.series,
    category: fund.category,
    name: null,
    custom: null,
    M1: null,
    M3: null,
    M6: null,
    Y1: null,
    Y3: null,
    Y5: null,
    Y10: null,
    0: null,
    equity: null,
    quotas: { name: null, data: [] },
    barQuotas: { name: null, data: [] },
  }));

Vue.use(Vuex);

export default new Vuex.Store({
  state: initialState,
  mutations,
  actions: {},
  plugins: [
    createPersistedState({
      key: "funds",
      reducer: state => ({
        selectedCurrency: state.selectedCurrency,
        selectedFunds: handleFunds(state.selectedFunds),
      }),
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 1 }),
        removeItem: key => Cookies.remove(key),
      },
    }),
  ],
});
