var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{
        'button-font-size': true,
        'button-gradient': !_vm.loading,
        'full-button': true,
      },attrs:{"large":"","elevation":"0","disabled":_vm.loading,"min-height":_vm.height,"max-height":_vm.height}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"popup-card vertical-spacing-mobile horizontal-spacing-mobile"},[_c('ranking-filter-selector',{attrs:{"loading":_vm.loading},on:{"close":_vm.close}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }