<template>
  <div id="currency-selectors">
    <div v-if="!isMobile">
      <v-btn
        v-for="(item, index) in currencies"
        :key="index"
        large
        elevation="0"
        width="91"
        :class="{
          'button-font-size': true,
          'button-gradient': !loading && $store.state.selectedCurrency === item,
          'button-inactive': !loading && $store.state.selectedCurrency !== item,
          'left-currency': index % 3 === 0,
          'middle-currency': index % 3 === 1,
          'right-currency': index % 3 === 2,
        }"
        :id="`currency-${item}`"
        :height="buttonHeight"
        :disabled="loading"
        @click="setCurrency(item)"
      >
        {{ $i18n.t(`selectors.currencies.${item}`) }}
      </v-btn>
    </div>
    <v-menu offset-y v-else>
      <template #activator="{ on, attrs }">
        <v-btn
          large
          elevation="0"
          class="full-button"
          :class="{
            'button-font-size': true,
            'button-gradient': !loading,
          }"
          :disabled="loading"
          :min-height="buttonHeight"
          :max-height="buttonHeight"
          v-on="on"
          v-bind="attrs"
          id="mobile-currency"
        >
          {{ $store.state.selectedCurrency }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in possibleCurrencies" :key="index" @click="setCurrency(item)">
          <v-list-item-title>{{ $i18n.t(`selectors.currencies.${item}`) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "CurrencySelector",
  data() {
    return {
      currencies: ["CLP", "USD", "CLF"],
    };
  },
  props: {
    loading: Boolean,
    buttonHeight: String,
  },
  computed: {
    possibleCurrencies() {
      return this.currencies.filter(currency => currency !== this.$store.state.selectedCurrency);
    },
    isMobile() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return true;
      }
      return false;
    },
    isMedium() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        return true;
      }
      return false;
    },
  },
  methods: {
    setCurrency(currency) {
      this.$store.commit("setCurrency", currency);
    },
  },
};
</script>

<style scoped></style>
