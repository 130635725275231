var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-card',{staticClass:"chart-card card-elevation"},[_c('div',{class:{
        'vertical-spacing horizontal-spacing': !_vm.isMedium,
        'vertical-spacing-mobile horizontal-spacing-mobile': _vm.isMedium,
      }},[(_vm.$store.state.selectedFunds.length === 0)?_c('div',[_c('v-card-title',[_vm._v(_vm._s(_vm.$i18n.t("table.empty")))])],1):_c('div',[_c('v-card-title',[_c('div',{staticClass:"card-title bold-text",style:({
              'font-size': _vm.isMobile ? '14px' : '22px',
            })},[_vm._v(" "+_vm._s(_vm.$i18n.t("table.title", { currency: _vm.$i18n.t(("selectors.currencies." + (_vm.$store.state.selectedCurrency))) }))+" ")]),_c('v-spacer'),(!_vm.isMobile)?_c('div',[_c('v-btn',{class:{
                'left-button': true,
                'button-inactive': !_vm.table,
                'button-active': _vm.table,
              },attrs:{"max-width":"50px","height":"40px","elevation":"0","id":"overview-view-table"},on:{"click":function($event){_vm.table = true}}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1),_c('v-btn',{class:{
                'right-button': true,
                'button-inactive': _vm.table,
                'button-active': !_vm.table,
              },attrs:{"max-width":"50px","height":"40px","elevation":"0","id":"overview-view-chart"},on:{"click":function($event){_vm.table = false}}},[_c('v-icon',[_vm._v("mdi-poll")])],1)],1):_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('v-list',[(_vm.table)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-poll")])],1),_c('v-list-item-title',{on:{"click":function($event){_vm.table = false}}},[_vm._v(_vm._s(_vm.$i18n.t("table.mobile.chart")))])],1):_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1),_c('v-list-item-title',{on:{"click":function($event){_vm.table = true}}},[_vm._v(_vm._s(_vm.$i18n.t("table.mobile.table")))])],1),_c('download-data',{attrs:{"standalone":false}}),_c('clear-funds',{attrs:{"standalone":false}})],1)],1)],1),(!_vm.isMobile)?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),(!_vm.isMobile)?_c('download-data',{attrs:{"standalone":true}}):_vm._e(),(!_vm.isMobile)?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),(!_vm.isMobile)?_c('clear-funds',{attrs:{"standalone":true}}):_vm._e()],1),(_vm.table)?_c('div',[_c('v-data-table',{staticClass:"ma-2 returns-table",attrs:{"headers":_vm.headers,"items":_vm.$store.state.selectedFunds,"items-per-page":_vm.perPage,"hide-default-footer":"","mobile-breakpoint":_vm.breakpoint},scopedSlots:_vm._u([{key:"item.0",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeFund(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('div',{staticClass:"bold-text chip-table",style:({ background: _vm.colors[index % 10], width: _vm.isMobile ? '150px' : '330px' })},[_c('div',{staticClass:"name-chip"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.custom",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'custom'}})]}},{key:"item.Y1",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'Y1'}})]}},{key:"item.Y2",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'Y2'}})]}},{key:"item.Y3",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'Y3'}})]}},{key:"item.Y5",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'Y5'}})]}},{key:"item.Y10",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'Y10'}})]}},{key:"item.equity",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('table-chip',{attrs:{"item":item,"index":index,"field":'equity',"equity":true}})]}}],null,true)})],1):_c('div',[_c('apexchart',{attrs:{"type":"bar","options":_vm.barOptions,"series":_vm.barQuotas,"height":"300"}})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }