export default {
  name: "es",
  options: {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    shortMonths: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    shortDays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    toolbar: {
      exportToSVG: "Descargar SVG",
      exportToPNG: "Descargar PNG",
      exportToCSV: "Descargar CSV",
      menu: "Menu",
      selection: "Seleccionar",
      selectionZoom: "Seleccionar Zoom",
      zoomIn: "Aumentar",
      zoomOut: "Disminuir",
      pan: "Navegación",
      reset: "Reiniciar Zoom",
    },
  },
};
