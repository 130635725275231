<template>
  <div>
    <div v-if="loading">
      <v-progress-linear indeterminate color="primary" />
    </div>
    <v-card v-else class="chart-card card-elevation">
      <div
        :class="{
          'vertical-spacing horizontal-spacing': !isMedium,
          'vertical-spacing-mobile horizontal-spacing-mobile': isMedium,
        }"
      >
        <div v-if="$store.state.selectedFunds.length === 0">
          <v-card-title>{{ $i18n.t("table.empty") }}</v-card-title>
        </div>
        <div v-else>
          <v-card-title>
            <div
              class="card-title bold-text"
              :style="{
                'font-size': isMobile ? '14px' : '22px',
              }"
            >
              {{
                $i18n.t("rankings.title", {
                  currency: $i18n.t(`selectors.currencies.${$store.state.selectedCurrency}`),
                  date: format_date($store.state.selectedRankingDate),
                })
              }}
            </div>
            <v-spacer />
            <ranking-download-data :standalone="true" v-if="!isMobile" />
            <div v-else>
              <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn icon class="ma-2" v-on="on" v-bind="attrs">
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <ranking-download-data :standalone="false" />
                </v-list>
              </v-menu>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <v-row no-gutters>
              <div>{{ $i18n.t("rankings.subtitle", { funds: filteredRankings.length }) }}</div>
              <v-spacer />
            </v-row>
          </v-card-subtitle>
          <v-data-table
            class="ma-2 returns-table"
            :headers="headers"
            :items="filteredRankings"
            :mobile-breakpoint="breakpoint"
            :items-per-page="perPage"
            :footer-props="{
              showFirstLastPage: true,
              itemsPerPageOptions: [15],
              showCurrentPage: true,
            }"
            @click:row="clickRow"
            @update:items-per-page="updateItemsPerPage"
            @update:page="updatePage"
          >
            <template #[`item.index`]="{ index }">
              {{ (currentPage - 1) * currentPerPage + index + 1 }}
            </template>
            <template #[`item.name`]="{ item, index }">
              <div class="bold-text chip-table" :style="{ background: colors[index % 10], width: isMobile ? '150px' : '250px' }">
                <div class="name-chip">{{ item.name }}</div>
              </div>
            </template>
            <template #[`item.ytd`]="{ item, index }">
              <table-chip :item="item" :index="index" :field="'ytd'" :tooltip="true" />
            </template>
            <template #[`item.M1`]="{ item, index }">
              <table-chip :item="item" :index="index" :field="'M1'" :tooltip="true" />
            </template>
            <template #[`item.M3`]="{ item, index }">
              <table-chip :item="item" :index="index" :field="'M3'" :tooltip="true" />
            </template>
            <template #[`item.M6`]="{ item, index }">
              <table-chip :item="item" :index="index" :field="'M6'" :tooltip="true" />
            </template>
            <template #[`item.Y1`]="{ item, index }">
              <table-chip :item="item" :index="index" :field="'Y1'" :tooltip="true" />
            </template>
            <template #[`item.Y2`]="{ item, index }">
              <table-chip :item="item" :index="index" :field="'Y2'" :tooltip="true" />
            </template>
            <template #[`item.Y3`]="{ item, index }">
              <table-chip :item="item" :index="index" :field="'Y3'" :tooltip="true" />
            </template>
            <template #[`item.Y5`]="{ item, index }">
              <table-chip :item="item" :index="index" :field="'Y5'" :tooltip="true" />
            </template>
            <template #[`item.Y10`]="{ item, index }">
              <table-chip :item="item" :index="index" :field="'Y10'" :tooltip="true" />
            </template>
            <template #[`item.equity`]="{ item, index }">
              <table-chip :item="item" :index="index" :field="'equity'" :equity="true" />
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
    <ranking-dialog :selected="selected" :dialog="dialog" @updateDialog="updateDialog" />
  </div>
</template>

<script>
/* eslint-disable arrow-parens, implicit-arrow-linebreak, operator-linebreak */
import RankingDownloadData from "@/components/rankings/RankingDownloadData.vue";
import TableChip from "@/components/TableChip.vue";
import RankingDialog from "@/components/rankings/RankingDialog.vue";
import colors from "@/helper/colors";

export default {
  name: "RankingTable",
  components: {
    RankingDownloadData,
    TableChip,
    RankingDialog,
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      colors,
      breakpoint: 1,
      currentPage: 1,
      currentPerPage: 15,
      selected: null,
      dialog: false,
      asset1Dependencies: {
        0: null,
        1: null,
        2: [1, 2],
        3: [3, 4],
        4: null,
        5: [5, 6, 7],
      },
      geography1Dependencies: {
        1: null,
        2: [1, 2, 3, 4, 5, 6],
        3: [7, 8, 9],
      },
      geography2Dependencies: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: [1, 2, 3, 4],
        8: [5, 6, 7],
        9: null,
      },
    };
  },
  computed: {
    perPage() {
      return 15;
    },
    headers() {
      return [
        {
          text: "#",
          sortable: false,
          value: "index",
          align: "right",
          visible: true,
        },
        {
          text: this.$i18n.t("rankings.headers.name"),
          value: "name",
          align: "left",
          visible: true,
        },
        {
          text: this.$i18n.t("rankings.headers.ytd"),
          value: "ytd",
          align: "right",
          visible: this.$store.state.selectedRankingFilters.headers.ytd,
        },
        {
          text: this.$i18n.t("rankings.headers.M1"),
          value: "M1",
          align: "right",
          visible: this.$store.state.selectedRankingFilters.headers.M1,
        },
        {
          text: this.$i18n.t("rankings.headers.M3"),
          value: "M3",
          align: "right",
          visible: this.$store.state.selectedRankingFilters.headers.M3,
        },
        {
          text: this.$i18n.t("rankings.headers.M6"),
          value: "M6",
          align: "right",
          visible: this.$store.state.selectedRankingFilters.headers.M6,
        },
        {
          text: this.$i18n.t("rankings.headers.Y1"),
          value: "Y1",
          align: "right",
          visible: this.$store.state.selectedRankingFilters.headers.Y1,
        },
        {
          text: this.$i18n.t("rankings.headers.Y2"),
          value: "Y2",
          align: "right",
          visible: this.$store.state.selectedRankingFilters.headers.Y2,
        },
        {
          text: this.$i18n.t("rankings.headers.Y3"),
          value: "Y3",
          align: "right",
          visible: this.$store.state.selectedRankingFilters.headers.Y3,
        },
        {
          text: this.$i18n.t("rankings.headers.Y5"),
          value: "Y5",
          align: "right",
          visible: this.$store.state.selectedRankingFilters.headers.Y5,
        },
        {
          text: this.$i18n.t("rankings.headers.Y10"),
          value: "Y10",
          align: "right",
          visible: this.$store.state.selectedRankingFilters.headers.Y10,
        },
        {
          text: this.$i18n.t("rankings.headers.equity"),
          value: "equity",
          align: "right",
          visible: this.$store.state.selectedRankingFilters.headers.equity,
        },
      ].filter(item => item.visible);
    },
    filteredRankings() {
      return this.$store.state.selectedRanking
        .filter(item => this.$store.state.selectedRankingFilters.fund[item.category])
        .filter(item => this.$store.state.selectedRankingFilters.asset1[item.asset1])
        .filter(
          item =>
            !this.asset1Dependencies[item.asset1] ||
            (this.$store.state.selectedRankingFilters.asset1[item.asset1] &&
              this.asset1Dependencies[item.asset1] &&
              this.$store.state.selectedRankingFilters.asset2[item.asset2]),
        )
        .filter(item => this.$store.state.selectedRankingFilters.theme[item.theme])
        .filter(item => this.$store.state.selectedRankingFilters.geography1[item.geography1])
        .filter(
          item =>
            !this.geography1Dependencies[item.geography1] ||
            (this.$store.state.selectedRankingFilters.geography1[item.geography1] &&
              this.geography1Dependencies[item.geography1] &&
              this.$store.state.selectedRankingFilters.geography2[item.geography2]),
        )
        .filter(
          item =>
            !this.geography2Dependencies[item.geography2] ||
            (this.$store.state.selectedRankingFilters.geography2[item.geography2] &&
              this.geography2Dependencies[item.geography2] &&
              this.$store.state.selectedRankingFilters.geography3[item.geography3]),
        )
        .filter(
          item =>
            !this.$store.state.selectedRankingFilters.equityLesserGreater[0] ||
            (this.$store.state.selectedRankingFilters.equityLesserGreater[0] &&
              item.equity >= this.$store.state.selectedRankingFilters.equityValues[0]),
        )
        .filter(
          item =>
            !this.$store.state.selectedRankingFilters.equityLesserGreater[1] ||
            (this.$store.state.selectedRankingFilters.equityLesserGreater[1] &&
              item.equity <= this.$store.state.selectedRankingFilters.equityValues[1]),
        );
    },
    isMobile() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return true;
      }
      return false;
    },
    isMedium() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        return true;
      }
      return false;
    },
  },
  methods: {
    removeFund(fund) {
      this.$store.commit("removeFund", fund);
    },
    format_plot(value, digits = 2) {
      return value.toLocaleString("es-CL", {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });
    },
    clickRow(e) {
      if (e.fundId in this.$store.state.series[e.category]) {
        this.selected = { series: this.$store.state.series[e.category][e.fundId], ...e };
        this.dialog = true;
      }
    },
    format_date(value) {
      if (value) {
        const toFormat = new Date(`${value} 12:00:00`);
        const formatter = new Intl.DateTimeFormat("es-CL", { month: "short" });
        const month = formatter.format(toFormat);
        return `${toFormat.getUTCDate()} ${month} ${toFormat.getUTCFullYear()}`;
      }
      return null;
    },
    updateDialog(e) {
      this.dialog = e;
    },
    updatePage(page) {
      this.currentPage = page;
    },
    updateItemsPerPage(perpage) {
      this.currentPerPage = perpage;
    },
  },
};
</script>

<style scoped>
.chart-card {
  border-radius: 20px !important;
}
.card-title {
  color: #4f4f4f;
}
.chip-table {
  color: white;
  text-align: center;
  border-radius: 80px;
}
.name-chip {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
}
.card-modal {
  border-bottom: none !important;

  box-shadow: none !important;
}
</style>
