<template>
  <v-autocomplete
    :label="$i18n.t('selectors.search.label')"
    v-model="chosenFund"
    :filter="filterFunds"
    :items="listOfFunds"
    item-text="name"
    background-color="white"
    return-object
    outlined
    hide-details
    autocomplete="off"
    :disabled="loading"
    :readonly="loading"
    height="60px"
    class="fund-search"
    color="noHighlight"
    :search-input.sync="currentInput"
    hide-no-data
  >
    <template #append>
      <v-btn
        elevation="0"
        icon
        rounded
        color="white"
        :disabled="loading || !chosenFund"
        :class="{
          'button-gradient': !loading && chosenFund,
          'inner-button': true,
        }"
        @click="addFund"
        id="add-search"
      >
        <v-icon v-if="loading || !chosenFund">mdi-magnify</v-icon>
        <v-icon v-else>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </template>
    <template #item="data">
      <v-list-item-content :id="`search-${data.item.value.fundId}-${data.item.value.series}`">
        <v-list-item-title>
          {{ `${data.item.name}` }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
/* eslint-disable arrow-parens */
export default {
  name: "FundSearch",
  data() {
    return {
      chosenFund: null,
      currentInput: null,
    };
  },
  props: {
    loading: Boolean,
  },
  computed: {
    listOfFunds() {
      if (!this.currentInput) {
        return [];
      }
      const output = [];
      Object.keys(this.$store.state.funds).forEach(key => {
        this.$store.state.funds[key].forEach(fund => {
          const temp = this.$store.state.series[key][fund.id].map(series => ({
            name: `${fund.name} - ${series.series}`,
            value: {
              fundId: fund.id,
              series: series.series,
              category: key,
            },
          }));
          output.push(...temp);
        });
      });
      return output;
    },
  },
  methods: {
    filterFunds(item, queryText, itemText) {
      const itemName = itemText.toLowerCase();
      const inSentence = word => itemName.includes(word);
      const words = queryText.toLowerCase().split(" ");
      return words.every(inSentence);
    },
    addFund() {
      this.$store.commit("addFund", this.chosenFund.value);
      this.chosenFund = null;
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.fund-search {
  border-radius: 30px 30px 30px 30px;
  max-width: 500px;
}
.inner-button {
  margin-top: -5px;
}
</style>
