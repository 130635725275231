<template>
  <div class="main-body">
    <div class="content">
      <div
        :class="{
          'ma-2': true,
          'horizontal-spacing': !isMobile,
        }"
      >
        <ranking-selectors :loading="$apollo.loading" />
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="3" v-if="!isMobile">
            <v-card elevation="0" class="fund-selector">
              <div
                :class="{
                  'vertical-spacing horizontal-spacing': !isMedium,
                  'vertical-spacing-mobile horizontal-spacing-mobile': isMedium,
                }"
              >
                <ranking-filter-selector :loading="$apollo.loading" />
              </div>
            </v-card>
            <br />
            <ad />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="9">
            <ranking-table :loading="$apollo.loading" />
          </v-col>
        </v-row>
      </div>
      <div v-if="isMobile" class="ma-2">
        <ad />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable arrow-parens */
/* eslint-disable operator-linebreak */
import { allFunds, getRankings } from "@/apollo/queries";
import RankingSelectors from "@/components/rankings/RankingSelectors.vue";
import RankingFilterSelector from "@/components/rankings/RankingFilterSelector.vue";
import RankingTable from "@/components/rankings/RankingTable.vue";
import Ad from "@/components/Ad.vue";
import { nameHandlers } from "@/mixins/nameHandlers";

export default {
  name: "Home",
  components: {
    RankingSelectors,
    RankingFilterSelector,
    RankingTable,
    Ad,
  },
  mixins: [nameHandlers],
  data() {
    return {};
  },
  computed: {
    selectedLength() {
      return this.$store.state.selectedFunds.length === 0;
    },
    queryFunds() {
      return this.$store.state.selectedFunds.map(fund => ({
        fund: fund.fundId,
        series: fund.series,
        category: fund.category,
      }));
    },
    isMobile() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return true;
      }
      return false;
    },
    isMedium() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        return true;
      }
      return false;
    },
  },
  apollo: {
    funds: {
      query: allFunds,
      result({ data, loading }) {
        if (!loading) {
          const output = {
            INV: {
              funds: [],
              classes: [],
              series: {},
            },
            MUT: {
              funds: [],
              classes: [],
              series: {},
            },
          };
          data.funds.forEach(fund => {
            output[fund.category].funds.push({
              name: this.formatName(fund.name),
              id: fund.fundId,
              classId: fund.classId,
            });
            output[fund.category].series[fund.fundId] = fund.series;
            if (fund.classId) {
              const classExists = output[fund.category].classes.find(elem => elem.id === fund.classId);
              if (!classExists) {
                output[fund.category].classes.push({
                  name: fund.className,
                  id: fund.classId,
                });
              }
            }
          });
          this.$store.commit("setFunds", output);
        }
      },
    },
    rankings: {
      query: getRankings,
      variables() {
        return {
          currency: this.$store.state.selectedCurrency,
          period: this.$store.state.selectedRankingPeriod.code,
        };
      },
      result({ data, loading }) {
        if (!loading) {
          const output = [];
          let date = null;
          data.rankings.forEach(item => {
            output.push({
              fundId: item.fundId,
              name: this.formatName(item.fund.name),
              asset1: item.fund.asset1Id,
              asset2: item.fund.asset2Id,
              theme: item.fund.themeId,
              geography1: item.fund.geography1Id,
              geography2: item.fund.geography2Id,
              geography3: item.fund.geography3Id,
              category: item.category,
              period: item.period,
              ytd: item.ytd,
              M1: item.month1,
              M3: item.month3,
              M6: item.month6,
              Y1: item.year1,
              Y2: item.year2,
              Y3: item.year3,
              Y5: item.year5,
              Y10: item.year10,
              ytdseries: item.ytdSeries,
              M1series: item.month1Series,
              M3series: item.month3Series,
              M6series: item.month6Series,
              Y1series: item.year1Series,
              Y2series: item.year2Series,
              Y3series: item.year3Series,
              Y5series: item.year5Series,
              Y10series: item.year10Series,
              equity: item.equity,
            });
            if (!date) {
              ({ date } = item);
            }
          });
          this.$store.commit("setRankings", { output, date });
        }
      },
    },
  },
};
</script>

<style></style>
