export default [
  "#e65d56", // red
  "#75bedf", // cyan
  "#76bc8a", // green
  "#efa24b", // orange
  "#7f6bb7", // purple
  "#0e3b57", // dark blue
  "#ec8493", // pink
  "#f0d317", // yellow
  "#b7b797",
  "#c09e83", // brown
  "#829ef9",
  "#2a77ba", // blue
  "#349c89",
  "#5291a5",
  "#676767", // gray
];
