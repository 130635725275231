<template>
  <div>
    <div>
      <div
        :class="{
          'ma-2': true,
          'horizontal-spacing': !isMobile,
        }"
      >
        <selectors :loading="$apollo.loading" />
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="3" v-if="!isMobile">
            <v-card elevation="0" class="fund-selector">
              <div
                :class="{
                  'vertical-spacing horizontal-spacing': !isMedium,
                  'vertical-spacing-mobile horizontal-spacing-mobile': isMedium,
                }"
              >
                <fund-selector :loading="$apollo.loading" />
              </div>
            </v-card>
            <br />
            <ad />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="9">
            <output-table :loading="$apollo.loading" />
            <br />
            <output-chart :loading="$apollo.loading" />
          </v-col>
        </v-row>
      </div>
      <div v-if="isMobile" class="ma-2">
        <ad />
        <br />
      </div>
    </div>
    <p>{{ $i18n.t("footer.dev") }}</p>
    <v-container>
      <p class="disclaimer-text">{{ $i18n.t("footer.fund") }}</p>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable arrow-parens */
/* eslint-disable operator-linebreak */
import { allFunds, getQuotas, getLongTermQuotas } from "@/apollo/queries";
import Selectors from "@/components/Selectors.vue";
import FundSelector from "@/components/FundSelector.vue";
import OutputTable from "@/components/OutputTable.vue";
import OutputChart from "@/components/OutputChart.vue";
import Ad from "@/components/Ad.vue";
import { nameHandlers } from "@/mixins/nameHandlers";

export default {
  name: "Home",
  components: {
    Selectors,
    FundSelector,
    OutputTable,
    OutputChart,
    Ad,
  },
  mixins: [nameHandlers],
  data() {
    return {};
  },
  computed: {
    selectedLength() {
      return this.$store.state.selectedFunds.length === 0;
    },
    queryFunds() {
      return this.$store.state.selectedFunds.map(fund => ({
        fund: fund.fundId,
        series: fund.series,
        category: fund.category,
      }));
    },
    isMobile() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return true;
      }
      return false;
    },
    isMedium() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        return true;
      }
      return false;
    },
  },
  apollo: {
    funds: {
      query: allFunds,
      result({ data, loading }) {
        if (!loading) {
          const output = {
            INV: {
              funds: [],
              classes: [],
              series: {},
            },
            MUT: {
              funds: [],
              classes: [],
              series: {},
            },
          };
          data.funds.forEach(fund => {
            output[fund.category].funds.push({
              name: this.formatName(fund.name),
              id: fund.fundId,
              classId: fund.classId,
            });
            output[fund.category].series[fund.fundId] = fund.series;
            if (fund.classId) {
              const classExists = output[fund.category].classes.find(elem => elem.id === fund.classId);
              if (!classExists) {
                output[fund.category].classes.push({
                  name: fund.className,
                  id: fund.classId,
                });
              }
            }
          });
          this.$store.commit("setFunds", output);
        }
      },
    },
    quotas: {
      query: getQuotas,
      skip() {
        return this.selectedLength;
      },
      variables() {
        return {
          currency: this.$store.state.selectedCurrency,
          funds: this.queryFunds,
          dates: {
            startDate: this.$store.state.selectedDate.startDate,
            endDate: this.$store.state.selectedDate.endDate,
          },
        };
      },
      result({ data, loading }) {
        if (!loading) {
          const output = [];
          data.quotas.forEach(item => {
            if (!item.currency) {
              return;
            }
            const exists = output.find(
              out =>
                // eslint-disable-next-line implicit-arrow-linebreak
                out.fundId === item.fundId && out.series === item.series && out.category === item.category,
            );
            if (exists) {
              exists.maximum = item.adjustedReturn;
              if (item.date === this.$store.state.selectedDate.startDate) {
                exists.startExists = true;
              }
              exists.data.push({
                x: `${item.date} CLT`,
                y: item.adjustedReturn,
              });
            } else {
              output.push({
                fundId: item.fundId,
                series: item.series,
                category: item.category,
                name: `${this.formatName(item.fund.name)} - ${item.series}`,
                maximum: item.adjustedReturn,
                startExists: item.date === this.$store.state.selectedDate.startDate,
                data: [
                  {
                    x: `${item.date} CLT`,
                    y: item.adjustedReturn,
                  },
                ],
              });
            }
          });
          this.$store.commit("setQuotas", {
            output,
            customName: this.$i18n.t(`table.headers.${this.$store.state.selectedDate.code}`),
          });
        }
      },
    },
    longTermQuotas: {
      query: getLongTermQuotas,
      skip() {
        return this.selectedLength;
      },
      variables() {
        return {
          currency: this.$store.state.selectedCurrency,
          funds: this.queryFunds,
          endDate: this.$store.state.longTermDate,
        };
      },
      result({ data, loading }) {
        if (!loading) {
          const output = [];
          data.longTermQuotas.forEach(item => {
            if (!item.currency) {
              return;
            }
            const exists = output.find(
              out =>
                // eslint-disable-next-line implicit-arrow-linebreak
                out.fundId === item.fundId && out.series === item.series && out.category === item.category,
            );
            if (exists) {
              exists[item.longTermPeriod] = item.adjustedReturn;
              if (["0", "PRE"].includes(item.longTermPeriod)) {
                exists.equity = item.netEquity;
              }
            } else {
              const toPush = {
                fundId: item.fundId,
                series: item.series,
                category: item.category,
                name: `${this.formatName(item.fund.name)} - ${item.series}`,
                M1: null,
                M3: null,
                M6: null,
                Y1: null,
                Y2: null,
                Y3: null,
                Y5: null,
                Y10: null,
                0: null,
                equity: null,
                barQuotas: [],
              };
              toPush[item.longTermPeriod] = item.adjustedReturn;
              if (["0", "PRE"].includes(item.longTermPeriod)) {
                toPush.equity = item.netEquity;
              }
              output.push(toPush);
            }
          });
          output.forEach(row => {
            row.barQuotas = {
              name: row.name,
              data: [
                {
                  x: this.$i18n.t("table.headers.Y1"),
                  y: row.Y1,
                },
                {
                  x: this.$i18n.t("table.headers.Y2"),
                  y: row.Y2,
                },
                {
                  x: this.$i18n.t("table.headers.Y3"),
                  y: row.Y3,
                },
                {
                  x: this.$i18n.t("table.headers.Y5"),
                  y: row.Y5,
                },
                {
                  x: this.$i18n.t("table.headers.Y10"),
                  y: row.Y10,
                },
              ],
            };
          });
          this.$store.commit("setLongTermQuotas", { output });
        }
      },
    },
  },
  beforeCreate() {
    let funds = null;
    let startDate = null;
    let endDate = null;
    let currency = null;
    if (this.$route.query) {
      // eslint-disable-next-line object-curly-newline
      ({ funds, startDate, endDate, currency } = this.$route.query);
    }
    if (localStorage.funds) {
      // eslint-disable-next-line object-curly-newline
      ({ funds, startDate, endDate, currency } = localStorage);
      localStorage.clear();
    }
    try {
      const innerFunds = funds.split(",").map(fund => {
        const parts = fund.split("_");
        return {
          fundId: Number.parseInt(parts[1], 10),
          series: parts[2],
          category: parts[0],
        };
      });
      const innerDate = {
        name: this.$i18n.t("selectors.dates.buttons.name.custom"),
        longName: this.$i18n.t("selectors.dates.buttons.longName.custom"),
        code: "custom",
        startDate,
        endDate,
      };
      this.$store.commit("addShareLink", { funds: innerFunds, date: innerDate, currency });
    } catch {
      // Ignore and continue
    }
  },
};
</script>

<style></style>
