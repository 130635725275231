import Vue from "vue";

Vue.filter("formatPercentages", value => {
  if (value) {
    return `${value.toLocaleString("es-CL", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}%`;
  }
  return null;
});

Vue.filter("formatEquity", value => {
  if (value) {
    return `${(value / 1000000).toLocaleString("es-CL", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}`;
  }
  return null;
});

Vue.filter("formatDate", value => {
  if (value) {
    const toFormat = new Date(value);
    const formatter = new Intl.DateTimeFormat("es-CL", { month: "short" });
    const month = formatter.format(toFormat);
    return `${toFormat.getUTCDate()} ${month} ${toFormat.getUTCFullYear()}`;
  }
  return null;
});
